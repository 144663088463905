import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import GeneralLayout from './layouts/GeneralLayout';
import Home from './pages/Home';
import AboutUs from './pages/About';
import HowTo from './pages/HowTo';
import PageNotFound from './pages/PageNotFound';
import LoginSignup from './pages/loginSignup';
import PersonalArea from './pages/personalarea';
import SignupSuccess from './pages/signup';
import ForgotPassword from './pages/forgotPassword';
import Admin from './pages/admin';
import AdminLogin from './pages/adminLogin';
import AdminLayout from './layouts/AdminLayout';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="" >
      <Route path="" element={<GeneralLayout />}>
        <Route index element={<Home />} />
        <Route path="howto" element={<HowTo />} />
        <Route path="aboutus" element={<AboutUs />} />
        <Route path='signupLogin'  >
          <Route index element={<LoginSignup />} />
          <Route
            path='signedUp'
            element={<SignupSuccess />}
          />
          <Route path='forgotPassword' element={<ForgotPassword />} />
        </Route>
        <Route path='personalArea/:id' element={<PersonalArea />} />
        <Route path='*' element={<PageNotFound />} />
      </Route>
      <Route path="" element={<AdminLayout />}>
        <Route path="admin" element={<Admin />} />
        <Route path="adminLogin" element={<AdminLogin />} />
      </Route >
    </Route >

  )
)

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
