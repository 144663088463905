import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpZA, faArrowUpAZ, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import './FiltersForOrders.css';
import PropTypes from 'prop-types';

const Date = ({ dateRange, handleDateRange }) => {
    return (
        <div className='Date'>
            <span className="title">Date</span>
            <div className='dateSec'>
                <label htmlFor='from'>
                    <span>From</span>
                    <input
                        type='date'
                        id='from'
                        onChange={handleDateRange}
                        value={dateRange['startDate'].replace(/\//g, "-")}
                    ></input>
                </label>

                <label htmlFor='to'>
                    <span>To</span>
                    <input
                        type='date'
                        id='to'
                        onChange={handleDateRange}
                        value={dateRange['endDate'].replace(/\//g, "-")}
                        min={dateRange['startDate'].replace(/\//g, "-")}
                    >
                    </input>
                </label>
            </div>
        </div>
    )
}
Date.propTypes = {
    dateRange: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
    }).isRequired,
    handleDateRange: PropTypes.func.isRequired,
};

const AscDescOrder = ({ handleAscDesc, ascDesc }) => {
    return (
        <div className='AscDescOrder'>
            <span className="title">Order</span>
            <div className='AscDescSec'>
                <div id="Asc" onClick={() => handleAscDesc('Asc')}>
                    {
                        ascDesc ?
                            <i className='checkSquare Ascending'>
                                <FontAwesomeIcon icon={faCheckSquare} />
                            </i> :
                            <div className="square"></div>
                    }
                    Large
                    <i><FontAwesomeIcon icon={faArrowUpZA} /></i>
                </div>
                <div id="Desc" onClick={() => handleAscDesc('Desc')}>
                    {
                        ascDesc ?
                            <div className="square" ></div>
                            : <i className='checkSquare Descending'>
                                <FontAwesomeIcon icon={faCheckSquare} />
                            </i>
                    }
                    Small
                    <i><FontAwesomeIcon icon={faArrowUpAZ} /></i>
                </div>
            </div>
        </div>
    )
}
AscDescOrder.propTypes = {
    handleAscDesc: PropTypes.func.isRequired,
    ascDesc: PropTypes.bool.isRequired,
};

const Currency = ({ handleCrypto, cryptoCurrencies }) => {
    return (
        <div className='Currency'>
            <span className="title">Currency</span>

            <ul className='currency'>
                {
                    ['Bitcoin', 'Ethereum', 'Ripple', 'Tether', 'Cardona', 'Chainlink'].map(

                        (elem, i) =>
                            <li onClick={() => handleCrypto(elem)} key={i.toString()}>
                                {
                                    cryptoCurrencies[elem] ?
                                        <i className='checkSquare'>
                                            <FontAwesomeIcon icon={faCheckSquare} />
                                        </i>
                                        : <div className="square"  >
                                        </div>
                                }{elem}
                            </li>
                    )
                }

            </ul>
        </div >

    )
}
Currency.propTypes = {
    handleCrypto: PropTypes.func.isRequired,
    cryptoCurrencies: PropTypes.shape({
        Bitcoin: PropTypes.bool.isRequired,
        Ethereum: PropTypes.bool.isRequired,
        Ripple: PropTypes.bool.isRequired,
        Tether: PropTypes.bool.isRequired,
        Cardona: PropTypes.bool.isRequired,
        Chainlink: PropTypes.bool.isRequired,
    }).isRequired,
};

const PriceRange = ({ priceRange, handlePriceRange }) => {
    return (
        <div className='PriceRange'>
            <span className="title">Range</span>
            <div className='Range'>
                <label>
                    <span>Min</span>
                    <input
                        type='number'
                        onChange={handlePriceRange}
                        value={priceRange.min}
                        id='Min'
                        min={30}
                        max={priceRange.max}
                    >
                    </input>
                </label>
                <label>
                    <span>Max</span>
                    <input
                        type='number'
                        onChange={handlePriceRange}
                        value={priceRange.max}
                        id='Max'
                    >
                    </input>
                </label>

            </div>
        </div>
    )
}
PriceRange.propTypes = {
    priceRange: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired
    }),
    handlePriceRange: PropTypes.func.isRequired
}

export const FiltersForOrders = ({
    dateRange,
    ascDesc, cryptoCurrencies,
    priceRange, handleAscDesc,
    handleCrypto, handlePriceRange,
    handleDateRange, handleReset
}) => {
    return (
        <div className='filters'>
            <Date dateRange={dateRange} handleDateRange={handleDateRange} />
            <AscDescOrder ascDesc={ascDesc} handleAscDesc={handleAscDesc} />
            <Currency handleCrypto={handleCrypto} cryptoCurrencies={cryptoCurrencies} />
            <PriceRange handlePriceRange={handlePriceRange} priceRange={priceRange} />
            <button className='resetBtn' onClick={handleReset}>Reset</button>
        </div>
    )
}

FiltersForOrders.propTypes = {
    dateRange: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired
    }),
    ascDesc: PropTypes.bool.isRequired,
    cryptoCurrencies: PropTypes.shape({
        Bitcoin: PropTypes.bool.isRequired,
        Ethereum: PropTypes.bool.isRequired,
        Ripple: PropTypes.bool.isRequired,
        Tether: PropTypes.bool.isRequired,
        Cardona: PropTypes.bool.isRequired,
        Chainlink: PropTypes.bool.isRequired,
    }).isRequired,
    priceRange: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired
    }),
    handleAscDesc: PropTypes.func.isRequired,
    handleCrypto: PropTypes.func.isRequired,
    handlePriceRange: PropTypes.func.isRequired,
    handleDateRange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired
}