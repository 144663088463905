import PropTypes from "prop-types";
import BTC from '../../svg/btc.svg';
import USDT from '../../svg/tether.svg';
import ETH from '../../svg/eth.svg';
import LINK from '../../svg/Link.svg';
import XRP from '../../svg/xrp.svg';
import ADA from '../../svg/Ada.svg';
import './Order.css';
const imageFinder = (name) => {
    switch (name) {
        case 'BTC':
            return BTC;
        case 'USDT':
            return USDT;
        case 'ETH':
            return ETH;
        case 'LINK':
            return LINK;
        case 'XRP':
            return XRP;
        case 'ADA':
            return ADA;
        default:
            break;
    }
}
const dateFormat = (date) => date.slice(0, date.indexOf('T')).replace(/-/g, '/');
export const Order = ({ order, option, index, handleCanceledOrder }) =>
    <article className="Order" key={index.toString()}
        style={{
            'animation': `orderDisp ${0.5 + (index * 0.1)}s forwards ease-in-out`
        }}>
        <header className="upperPart" >
            <div className="text">
                <p className="id">Order #{order['_id']}</p>
                <time className="dateTime" dateTime={dateFormat(order['createdAt'])}>
                    {dateFormat(order['createdAt'])}
                </time>
            </div>
            <img src={imageFinder(order['asset'])} alt={order['asset'] + ' logo'}></img>
        </header>
        <section className="middlePart">
            <h2 className="Name">{order['asset']}</h2>
            <div className="cryptoVal">
                <span>amount</span>
                <span>{order['crypto']}</span>
            </div>
            <div className="usdVal">
                <span>Value</span>
                <span>${order['fiat']}</span>
            </div>
        </section>
        {
            option.name === 'Pending Order' ?
                <button aria-label="Cancel Order" onClick={() => handleCanceledOrder(order['_id'])}>Cancel</button> :
                <div style={{ height: '10px' }}></div>
        }
    </article>;

Order.propTypes = {
    order: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        asset: PropTypes.string.isRequired,
        crypto: PropTypes.number.isRequired,
        fiat: PropTypes.number.isRequired,
    }).isRequired,
    option: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,
};