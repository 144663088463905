import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateAdmin } from "../redux/admin";
import VerificationCode from "../components/verificationCodeEntry/verificationCode.js";
import exclamationMark from "../svg/exclamationMark.svg";

function AdminLogin() {
    const [adminIdResp, setadminIdResp] = useState('')
    const dispatch = useDispatch();
    const [adminId, setAdminId] = useState('');
    const [password, setPassword] = useState('');
    const [verifiCode, setVerifiCode] = useState([null, null, null, null, null, null]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({})
    const navigate = useNavigate();

    const handleAdminId = (e) => {
        const adminId = e.target.value;
        setAdminId(adminId);
        setError({});
    }
    const handlePassword = (e) => {
        const passwordVl = e.target.value;
        setPassword(passwordVl);
        setError({});
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        if (adminIdResp === '') {
            const adminData = {
                adminId: adminId,
                password: password
            };

            const request = await fetch(
                'http://localhost:4000/api/admin/adminLogin',
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                    credentials: 'include',
                    body: JSON.stringify(adminData)
                }
            );
            const response = await request.json();

            if (Object.keys(response).length === 1) {
                setadminIdResp(response['adminId']);
            } else {
                setError(response);
                if (!response['adminId'].includes('error ')) setAdminId('');
                if (!response['password'].includes('error ')) setPassword('');
            }
        }
    }
    const handleCodeSbm = async (e) => {
        e.preventDefault();
        const code = verifiCode.join('')
        const adminDetails = {
            code: code,
            adminId: adminIdResp
        }
        const request = await fetch(
            'http://localhost:4000/api/admin/adminLoginCode',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                credentials: 'include',
                body: JSON.stringify(adminDetails)
            }
        )
        const response = await request.json()

        if (response['_id']) {
            setLoading(false);
            dispatch(updateAdmin(response));
            navigate('/admin');
        } else {
            setLoading(false);
        }
    }
    const handleVerifiCode = (e) => {
        const index = parseInt(e.target.id);
        let value = parseInt(e.target.value);
        
        const newArr = [...verifiCode];
        if (isNaN(value) && index > 0) {
            let leftField = document.getElementById((index - 1).toString());
            leftField.select();
        }
        if (!isNaN(value) && index < 5) {
            let rightField = document.getElementById((index + 1).toString());
            rightField.select();
        }
        if (index === 0 || index === 5) {
            if (value < 0) {
                value = 0
            }
        }
        if (index === 0) {
            if (value > 9) {
                value = value.toString();
                value = parseInt(value[0]);
            }
        }
        if (index === 5) {
            if (value > 9) {
                value = value.toString();
                value = parseInt(value[0]);
            }
        }

        newArr[index] = value;
        setVerifiCode(newArr)
    }
    return (

        <div className="adminLoginSec" >
            {
                adminIdResp ?
                    <div className="codeVerify">
                        <img
                            src={exclamationMark}
                            alt="Exclamation mark icon."
                        ></img>
                        <span className="title">Your code of authentication has been sent to your account.</span>
                        <span className="resendCode">Resend Code.</span>
                        
                        <VerificationCode verifiCode={verifiCode} handleVerifiCode={handleVerifiCode}
                            setLoading={setLoading} loading={loading}
                            handleCodeSbm={handleCodeSbm} />
                    </div> :
                    <form className="adminLogin" onSubmit={handleLogin}>
                        < span className="title" > Please fill in your unique admin login details below.</span >
                        <label>Admin ID
                            <input
                                className={Object.keys(error).length > 1 ? 
                                    error['adminId'].includes('error') ? "" :"error" : ""}
                                type="text"
                                placeholder={Object.keys(error).length > 1 ?
                                    error['adminId'].includes('error') ? "" : error['adminId']
                                    : ""}
                                onChange={handleAdminId}
                                value={adminId}
                            >
                            </input>
                        </label>
                        <label>Password
                            <input
                                className={Object.keys(error).length > 1 ? 
                                    error['password'].includes('error') ? "" :"error" : ""}
                                type="password"
                                placeholder={Object.keys(error).length > 1 ?
                                    error['password'].includes('error') ? "" : error['password']
                                    : ""}
                                onChange={handlePassword}
                                value={password}
                            >
                            </input>
                        </label>
                        <span className="forgotPass">forgot password?</span>
                        <button>Login in</button>
                    </form>

            }
        </div >
    );
}

export default AdminLogin;