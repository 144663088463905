import USDT from '../svg/tether.svg';
import BTC from '../svg/btc.svg';
import ETH from '../svg/eth.svg';
import LINK from '../svg/Link.svg';
import XRP from '../svg/xrp.svg';
import ADA from '../svg/Ada.svg';
export const cryptoList = [
    { name: 'USDT', image: USDT },
    { name: 'BTC', image: BTC },
    { name: 'ETH', image: ETH },
    { name: 'LINK', image: LINK },
    { name: 'XRP', image: XRP },
    { name: 'ADA', image: ADA },
];
export const networkList = [
    { name: 'BNB Smart Chain (BEP20)' },
    { name: 'EOS' },
    { name: 'AVAX C-Chain' },
    { name: 'BNB Beacon Chain (BEP20)' },
    { name: 'Ethereum (ERC20)' },
    { name: 'Polygon' },
    { name: 'Solana' },
    { name: 'Tezos' },
    { name: 'Tron (TRC20)' }
]