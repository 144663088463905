import aboutCryptoIllus from '../svg/aboutCryptoIllus.svg';
import phone from '../svg/phone.svg';
import generalIllustration from '../svg/generalIllustration.svg';

const SquaresWithInfo = ({ elem ,index}) => <div className={"SquaresWithInfo box"+index.toString()}>
    <img
        src={elem['illustration']}
        alt='illustration of key point'
    ></img>
    <p>{elem.descrip}</p>
</div>

function AboutUs() {
    const name = 'BICrypto';
    const keyPoints = [
        {
            descrip: "At BiCrypt, we simplify the complexity of cryptocurrencies for newcomers with an intuitive platform. Our customer support team is always available to assist you."
            , illustration: phone
        },
        {
            descrip: "We offer a wide range of cryptocurrencies, including Bitcoin, Ethereum, and many others. We also support fiat currencies, which means that you can easily deposit and withdraw funds using your local currency."
            , illustration: aboutCryptoIllus
        },
        {
            descrip: "Our experienced team of finance, technology, and cybersecurity professionals is passionate about cryptocurrencies and committed to providing a secure and reliable platform that stays up-to-date with the latest industry developments."
            , illustration: generalIllustration
        }
    ]

    return (
        <div className="AboutUs">
            <div className='leftSide'>
                <h1>Your Gateway to the World of Cryptocurrencies: {name}</h1>
                <p className="shortCatch">Welcome to {name}, a secure and reliable platform for buying, selling, and trading cryptocurrencies. Our mission is to provide a user-friendly experience for anyone who wants to participate in the exciting world of digital currencies.</p>
                <p className="bottomPart">Thank you for choosing {name}. We are excited to be a part of your journey into the world of cryptocurrencies. If you have any questions or feedback, please don't hesitate to contact us.</p>
            </div>
            <div className="Squares">
                {
                    keyPoints.map((elm, index) =>
                        <SquaresWithInfo key={index.toString()} elem={elm} index={index}  />
                    )
                }
            </div>

        </div>
    );
}

export default AboutUs;