import { NavLink, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateAdmin } from "../redux/admin";


function AdminLayout() {
    const { adminData } = useSelector((state) => state.admin);
    const dispatch = useDispatch();
    const handleLogout = async (e) => {
        document.cookie = 'ad_t=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        dispatch(updateAdmin({
            adminId: "",
            password: "",
            multifactorAuthCode: 0,
            sessionAuthentication: false
        }));
    }
    return (
        <div className="AdminLayout">
            <header>
                <h1><span>BI</span>CRYPTO</h1>
                {
                    adminData['adminId'] === '' ?
                        <></> :
                        < div className="links">
                            <NavLink className="link" onClick={handleLogout} to="/adminLogin">Log out</NavLink>
                            <NavLink className="link">Notify Superiors</NavLink>
                        </div>
                }
            </header>
            <main>
                <Outlet />
            </main>
        </div >
    );
}

export default AdminLayout;