import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import binance from '../svg/binance.svg';
import bitfinex from '../svg/bitfinex.svg';
import coinbase from '../svg/coinbase.svg';
import huobi from '../svg/huobi.svg';
import kraken from '../svg/kraken.svg';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

const BoxDesign = ({ elem, setExchangeTuto }) => {
    return (
        <div className='boxInfo' onClick={() => { setExchangeTuto(elem.name); }}>
            <div className='upperPart'>
                <img src={elem.img} alt='cryptoexchnage logo'></img>
                <h1>{elem.name}</h1>
            </div>
            <div className='lowerPart'>
                <p>{elem.description}</p>
                <i><FontAwesomeIcon icon={faChevronRight} /></i>
            </div>
        </div>
    )
}
function HowTo() {
    const [exchangeTuto, setExchangeTuto] = useState();
    const cyrptoExchangesDeposit = [
        {
            name: "Binance",
            description: "Binance is one of the largest cryptocurrency exchanges in the world in terms of trading volume.",
            tutorialDeposit: [
                "Log in to your Binance account and click on 'Wallet' in the top right corner of the screen."
                , "Click on 'Deposit' next to the cryptocurrency you want to deposit."
                , "Copy the deposit address or scan the QR code."
                , "Send the cryptocurrency from your wallet to the deposit address."
                , "Wait for the transaction to be confirmed on the blockchain."
                , "Your deposit should now show up in your Binance account."
            ]
            , tutorialWithdraw: [
                "Log in to your Binance account and click on 'Withdraw' under 'Funds'."
                , "Select the cryptocurrency you want to withdraw and enter the amount."
                , "Enter the address of the wallet you want to withdraw to."
                , "Enter any additional required information, such as a destination tag or memo."
                , "Click 'Submit' and confirm the withdrawal through email verification."
            ],
            img: binance
        },
        {
            name: "Coinbase",
            description: "Coinbase is a popular cryptocurrency exchange that is based in the United States.",
            tutorialDeposit: [
                "Log in to your Coinbase account and click on 'Accounts' in the top menu."
                , "Select the cryptocurrency you want to deposit and click on 'Receive'."
                , "Copy the deposit address or scan the QR code."
                , "Send the cryptocurrency from your wallet to the deposit address."
                , "Wait for the transaction to be confirmed on the blockchain."
                , "Your deposit should now show up in your Coinbase account."

            ]
            , tutorialWithdraw: [
                "Log in to your Coinbase account and click on 'Accounts'."
                , "Select the cryptocurrency you want to withdraw from and click on 'Send'."
                , "Enter the recipient's wallet address and the amount you want to send."
                , "Click 'Continue' and confirm the transaction."
            ],
            img: coinbase
        },
        {
            name: "Kraken",
            description: "Kraken is a US-based cryptocurrency exchange that is popular among traders for its low fees and advanced trading features.",
            tutorialDeposit: [
                "Log in to your Kraken account and click on 'Funding' in the top menu."
                , "Select the cryptocurrency you want to deposit and click on 'Deposit'."
                , "Copy the deposit address or scan the QR code."
                , "Send the cryptocurrency from your wallet to the deposit address."
                , "Wait for the transaction to be confirmed on the blockchain."
                , "Your deposit should now show up in your Kraken account."

            ]
            ,
            tutorialWithdraw: [
                "Log in to your Kraken account and click on 'Funding' and then 'Withdraw'."
                , "Select the cryptocurrency you want to withdraw and enter the amount."
                , "Enter the address of the wallet you want to withdraw to."
                , "Enter any additional required information, such as a destination tag or memo."
                , "Click 'Review Withdrawal' and confirm the withdrawal."
            ],
            img: kraken
        },
        {
            name: "Bitfinex",
            description: "Bitfinex is a cryptocurrency exchange that is based in Hong Kong. It is known for its advanced trading features and high liquidity.",
            tutorialDeposit: [
                " Log in to your Bitfinex account and click on 'Deposit' in the top menu."
                , "Select the cryptocurrency you want to deposit and click on 'Deposit'."
                , "Copy the deposit address or scan the QR code."
                , "Send the cryptocurrency from your wallet to the deposit address."
                , "Wait for the transaction to be confirmed on the blockchain."
                , "Your deposit should now show up in your Bitfinex account."
            ]
            , tutorialWithdraw: [
                "Log in to your Bitstamp account and click on 'Withdrawal' under 'Deposit & Withdrawal'."
                , "Select the cryptocurrency you want to withdraw and enter the amount."
                , "Enter the address of the wallet you want to withdraw to."
                , "Click 'Withdraw' and confirm the transaction."
            ],
            img: bitfinex
        },
        {
            name: "Huobi",
            description: "Huobi is a cryptocurrency exchange that is based in China. It is one of the largest exchanges in the world and offers a wide range of trading pairs.",
            tutorialDeposit: [
                "Log in to your Huobi account and click on 'Balances' in the top menu."
                , "Select the cryptocurrency you want to deposit and click on 'Deposit'."
                , "Copy the deposit address or scan the QR code."
                , "Send the cryptocurrency from your wallet to the deposit address."
                , "Wait for the transaction to be confirmed on the blockchain."
                , "Your deposit should now show up in your Huobi account."
            ]
            , tutorialWithdraw: [
                "Log in to your Huobi account and click on 'Balances' at the top of the page."
                , "Select the cryptocurrency you want to withdraw and click on 'Withdraw'."
                , "Enter the recipient's wallet address and the amount you want to send."
                , "Enter any additional required information, such as a tag or memo."
                , "Click 'Submit' and confirm the withdrawal through email verification."
            ],
            img: huobi
        }
    ];

    const handleExchangeClicked = () => {
        const elem = cyrptoExchangesDeposit.find(obj => obj.name === exchangeTuto);
        return elem;
    }
    return (
        <div className="HowTo">
            <h1 className='mainTitle'>How to transfert or deposit.</h1>
            <div className='middleSection'>
                <div className="exchangeShortdescript"
                    style={handleExchangeClicked() ? { maxWidth: '600px' } : { maxWidth: '880px' }}
                >
                    {
                        cyrptoExchangesDeposit.map((exchange, index) =>
                            <BoxDesign key={index.toString()} setExchangeTuto={setExchangeTuto} elem={exchange} />
                        )
                    }
                </div>
                {
                    handleExchangeClicked() ?
                        <div className="DepoWithtutorial" >
                            <div className='upperPart'>
                                <img src={handleExchangeClicked()['img']} alt='cryptoexchange logo'></img><h1>Deposit</h1>
                            </div>

                            <ol>
                                {
                                    handleExchangeClicked() ?
                                        handleExchangeClicked()['tutorialDeposit'].map(elem => <li>{elem}</li>)
                                        : ''
                                }
                            </ol>
                            <h1 className='lowerpart'>Withdraw</h1>
                            <ol>
                                {
                                    handleExchangeClicked() ?
                                        handleExchangeClicked()['tutorialWithdraw'].map(elem => <li>{elem}</li>)
                                        : ''
                                }
                            </ol>
                        </div> : ''
                }

            </div>

        </div>
    );
}

export default HowTo;