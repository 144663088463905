import shoppingBag from '../svg/shoppingBag.svg';
import totalOrders from '../svg/totalOrders.svg';
import totalVisitors from '../svg/totalVisitors.svg';
import pendingOrders from '../svg/pendingOrders.svg';
//------------------------------------------//
import message from '../svg/message.svg';
import dashBoard from '../svg/dashBoard.svg';
import completedOrder from '../png/completedOrder.png';
//import OrderHistory from '../png/historyOrder.png';
import OrderPending from '../png/pendingOrder.png';
import adminIcon from '../png/adminIcon.png';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//import io from "socket.io-client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faUser, faSearch } from '@fortawesome/free-solid-svg-icons';

//------------------------------//
//     orders sec (sub menu) //
//------------------------------//
const allordersFinder = (usersData) => {
    const orders = [];
    usersData.forEach((elem) =>
        elem['order'].forEach(order => { if (order['status'] !== 'cancelled') orders.push(order) })
    );
    return orders.length;
}
const totalSalesFinder = (usersData) => {
    let sales = 0;
    usersData.forEach(elem =>
        elem['order'].forEach(
            order => {
                if (order['status'] === 'completed') sales += order['fiat']
            }));
    return sales;
}
const totalVisitsFinder = (usersData) => usersData.length;
const pendingOrderFinder = (usersData) => {
    let pendingOrders = 0;
    usersData.forEach(elem =>
        elem['order'].forEach(
            order => {
                if (order['status'] === 'pending') pendingOrders += 1
            }));
    return pendingOrders;
}
const DataWidget = ({ usersData }) => {
    const allOrders = allordersFinder(usersData);
    const totalSales = totalSalesFinder(usersData);
    const totalVisits = totalVisitsFinder(usersData);
    const pendingorders = pendingOrderFinder(usersData);

    return (
        <div className="DataWidget">
            <div className='widget'>
                <img src={shoppingBag} alt='shopping bag icon'></img>
                <div className='TextInfo'>
                    <span>Total Sales</span>
                    <span className='sum'>${totalSales}</span>
                </div>
            </div>
            <div className='widget'>
                <img src={totalOrders} alt='total order icon'></img>
                <div className='TextInfo'>
                    <span>All Orders</span>
                    <span className='sum'>{allOrders}</span>
                </div>
            </div>
            <div className='widget'>
                <img src={totalVisitors} alt='total order icon'></img>
                <div className='TextInfo'>
                    <span>Total Visitors</span>
                    <span className='sum'>{totalVisits}</span>
                </div>
            </div>
            <div className='widget'>
                <img src={pendingOrders} alt='total order icon'></img>
                <div className='TextInfo'>
                    <span>Pending Orders</span>
                    <span className='sum'>{pendingorders}</span>
                </div>
            </div>
        </div>
    )
}
const dateFormater = (dateString) => {
    const date = new Date(dateString);
    const dateSperated = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    const time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    return {
        date: dateSperated,
        time: time
    };
}
const RowDisplay = ({ user, date, index, order }) =>
    <tr className="user" key={index.toString()}>
        <td className='orderId'>{order['_id']}</td>
        <td className="creationTime">
            <span><em style={{ color: 'black' }}>Time: </em>{date['time']}</span>
            <span><em style={{ color: 'black' }}>Date: </em>{date['date']}</span>
        </td>
        <td>{user['email']}</td>
        <td style={order['status'] === 'completed' ? { color: '#02a75a' } : { color: '#0044e1' }}>{order['status']}</td>
        <td>${order['fiat']}</td>
        <td>{order['crypto']}</td>
        <td>{order['asset']}</td>
    </tr>;
const SearchFields = ({
    email,
    date,
    orderId,
    asset,
    handleEmail,
    handleDate,
    handleOrderId,
    handleAsset
}) => {
    return (
        <form className="SearchFields">
            <label>
                Customer Email
                <input
                    type="text"
                    placeholder="Enter Customer Email"
                    value={email}
                    onChange={handleEmail}
                >
                </input>
            </label>
            <label>
                Date
                <input
                    type="date"
                    onChange={handleDate}
                    value={date}
                >
                </input>
            </label>
            <label>
                Order ID
                <input
                    type="text"
                    placeholder="Enter User ID"
                    value={orderId}
                    onChange={handleOrderId}
                >
                </input>
            </label>
            <label>
                Asset
                <input
                    type="text"
                    placeholder="Enter Asset Symbol"
                    value={asset}
                    onChange={handleAsset}
                >
                </input>
            </label>
        </form>
    )
}
const OrderOption = ({ handleOrderCancel, handleOrderFilled }) => {
    return (
        <tr className='OrderOption'>
            <div className='orderCancelled' onClick={handleOrderCancel}>Order Cancelled</div>
            <div className='messageUser'>Message User</div>
            <div className='orderCompleted' onClick={handleOrderFilled}>Order Fulfilled</div>
        </tr>
    )
}
const Userlist = ({ usersData, orderStatus,
    handleOrderCancel,
    handleOrderFilled }) => {

    return (
        <table className="Userlist">
            <thead>
                <tr className="upperTitle">
                    <th>Order Id</th>
                    <th>Date</th>
                    <th>User Email</th>
                    <th>Status</th>
                    <th>Paid Amount</th>
                    <th>Crypto Amount</th>
                    <th>Crypto</th>
                </tr>
            </thead>
            <tbody>
                {
                    usersData.map((user, i) =>
                        user['order'].map((order, index) => {
                            const date = dateFormater(order['createdAt']);
                            if (!orderStatus && order['status'] !== 'cancelled') {
                                return <RowDisplay index={index} user={user} date={date} order={order} />;
                            }

                            if (order['status'] === orderStatus) {
                                return orderStatus === 'pending' ?
                                    <>
                                        <RowDisplay index={index} user={user} date={date} order={order} />
                                        <OrderOption
                                            handleOrderCancel={handleOrderCancel}
                                            handleOrderFilled={handleOrderFilled}
                                        />
                                    </> : <RowDisplay index={index} user={user} date={date} order={order} />;
                            }
                            return <></>
                        }
                        )
                    )
                }
            </tbody >

        </table>
    )
}
//------------------------------//
//     messaging sec (sub menu) //
//------------------------------//

const SearchMessage = (
    {
        date, userId,
        handleDate, handleUserId
    }
) => {
    return (
        <div className='SearchMessage'>

            <label>
                Date
                <input
                    type='date'
                    onChange={handleDate}
                    value={date}
                >
                </input>
            </label>
            <label>
                User Id
                <input
                    type='text'
                    placeholder='Enter UserID'
                    onChange={handleUserId}
                    value={userId}
                >
                </input>
            </label>
        </div>
    )
}
const ChatSec = ({ selectUser }) => {
    const arrMessages = [...selectUser['messages']]

    return (
        <div className='chatmessages'>
            {
                arrMessages.map((elem, i) =>
                    elem['sender'] === 'admin' ?
                        <div className='admin' key={i.toString()} id={i.toString() + 'userAdminMessages'}>
                            <img src={adminIcon} alt='Admin icon' className='icon' ></img><p>{elem['message']}</p>
                        </div> :
                        <div className='user' key={i.toString()}>
                            <p >{elem['message']}</p>
                            <div className='icon'><i><FontAwesomeIcon icon={faUser} /></i></div>
                        </div>
                )
            }
        </div>
    )
}
const HeaderChatSec = ({ selectUser, message, handleMessage, handleFoundMessage }) => {
    return (
        <div className='userId'>
            <div className='icon' id='icon'>
                <FontAwesomeIcon icon={faUser} />
            </div>

            {selectUser ? selectUser['userId'] : ''}

            <input
                type='text'
                placeholder='Enter Message To Search'
                onChange={handleMessage}
                value={message}
            ></input>

            <button id='btnSearch' onClick={() => {
                const elem = document.getElementById('btnSearch');
                elem.blur();
                handleFoundMessage();
            }}>
                <FontAwesomeIcon icon={faSearch} />
            </button>
        </div>
    )
}
const Messages = ({
    messages = [{
        userId: 'asdf29en229n92nw[ai92f',
        messages: [{
            sender: 'admin',
            message: `Dear Customer,We regret to inform you that your order has been cancelled. We apologize for any inconvenience this may have caused.If you have any questions or concerns regarding the cancellation of your order, please do not hesitate to contact us. Our customer service team will be happy to assist you in any way possible.Thank you for considering our products and services. We hope to have the opportunity to serve you in the future.Best regards`
        }],
        updatedAt: '2023-06-15'
    }, {
        userId: 'asdf29en229n9nwi92f',
        messages: [{
            sender: 'admin',
            message: `Dear Customer,

            We regret to inform you that your order has been cancelled. We apologize for any inconvenience this may have caused.
            
            If you have any questions or concerns regarding the cancellation of your order, please do not hesitate to contact us. Our customer service team will be happy to assist you in any way possible.
            
            Thank you for considering our products and services. We hope to have the opportunity to serve you in the future.
            
            Best regards`
        }],
        updatedAt: '2023-06-16'
    }, {
        userId: 'asdf29en229n92wi92f',
        messages: [{
            sender: 'admin',
            message: `Dear Customer,

            We regret to inform you that your order has been cancelled. We apologize for any inconvenience this may have caused.
            
            If you have any questions or concerns regarding the cancellation of your order, please do not hesitate to contact us. Our customer service team will be happy to assist you in any way possible.
            
            Thank you for considering our products and services. We hope to have the opportunity to serve you in the future.
            
            Best regards`
        }],
        updatedAt: '2023-06-17'
    }, {
        userId: 'asdf29en229n92nwi92',
        messages: [{
            sender: 'admin',
            message: `Dear Customer,

            We regret to inform you that your order has been cancelled. We apologize for any inconvenience this may have caused.
            
            If you have any questions or concerns regarding the cancellation of your order, please do not hesitate to contact us. Our customer service team will be happy to assist you in any way possible.
            
            Thank you for considering our products and services. We hope to have the opportunity to serve you in the future.
            
            Best regards`
        }],
        updatedAt: '2023-06-18'
    }, {
        userId: 'asdf29e229n92nwi92f',
        messages: [{
            sender: 'admin',
            message: `Dear Customer,

            We regret to inform you that your order has been cancelled. We apologize for any inconvenience this may have caused.
            
            If you have any questions or concerns regarding the cancellation of your order, please do not hesitate to contact us. Our customer service team will be happy to assist you in any way possible.
            
            Thank you for considering our products and services. We hope to have the opportunity to serve you in the future.
            
            Best regards`
        },
        {
            sender: 'user',
            message: `It has been 20 hours i am still waitting for my order what is going on.`
        },
        {
            sender: 'admin',
            message: `we know sir but we are doing all that we can.`
        }, {
            sender: 'user',
            message: `I hope you guys are not scammers.`
        },
        {
            sender: 'admin',
            message: `In the case of failure you shall be refounded.`
        }],
        updatedAt: '2023-06-15'
    }]
}) => {
    const [selectUser, setSelectedUser] = useState();
    const [message, setMessage] = useState('');
    const [foundMessage, setFoundMessage] = useState('');

    const handleFoundMessage = () => {
        const newArr = [...foundMessage];
        newArr.pop();
        const lastElm = newArr[newArr.length - 1];
        if (lastElm) {
            const scrollToM = document.getElementById((lastElm['index']).toString() + 'userAdminMessages');
            if (scrollToM) scrollToM.scrollIntoView();
        }
        setFoundMessage(newArr);
    }

    const handleMessage = (e) => {
        const text = e.target.value;
        const arrMessages = [...selectUser['messages']];
        const foundMessages = [];

        arrMessages.forEach((elem, i) => {
            if (elem['message'].toUpperCase().includes(text.toUpperCase()) && text !== '') {
                foundMessages.push({ ...elem, index: i });
            }
        })
        setFoundMessage(foundMessages);
        const lastM = foundMessages[foundMessages.length - 1];
        if (foundMessages.length > 0) {
            const scrollToM = document.getElementById((lastM['index']).toString() + 'userAdminMessages');
            if (scrollToM) scrollToM.scrollIntoView();
        }
        setMessage(e.target.value);
    }

    const [date, setDate] = useState('');
    const handleDate = (e) => { setDate(e.target.value); }
    const [userId, setUserId] = useState('');
    const handleUserId = (e) => setUserId(e.target.value);

    // const [filteredMessage, setFilteredMessage] = useState('');
    // useEffect(() => {
    //     const newMessages = [];

    // }, [message, date, userId]);
    return (
        <div className='MessageSec'>
            <SearchMessage date={date} userId={userId} handleDate={handleDate} handleUserId={handleUserId} />
            <div className='Messages'>
                <div className='messageUser'>
                    {
                        messages.map((elem, i) =>
                            <div className={selectUser ? selectUser['userId'] === elem['userId'] ? "user active" : "user" : "user"} key={i.toString()} onClick={() => { setSelectedUser(elem) }}>
                                <span className='userId'><strong>UserID: </strong>{elem['userId']}</span>
                                <span className='dateOfCreation'><strong>Date: </strong>{elem['updatedAt']}</span>
                            </div>
                        )
                    }
                </div>
                {
                    selectUser ?
                        <div className='chatSec'>
                            <HeaderChatSec selectUser={selectUser} message={message} handleMessage={handleMessage} handleFoundMessage={handleFoundMessage} />

                            <ChatSec selectUser={selectUser} />

                            <div className='sendingMessage'>
                                <input type='text' placeholder='Your message'>
                                </input>
                                <button><i><FontAwesomeIcon icon={faPaperPlane} /></i></button>
                            </div>

                        </div> : <></>
                }
            </div >
        </div>
    )
}
//------------------------------//
//         Main menus           //
//------------------------------//
const LeftSec = ({ menu, handleMenu }) => {
    return (
        <nav className="LeftSec">
            {
                menu.map((elem, i) =>
                    <div
                        className='option'
                        key={i.toString()}
                        onClick={() => handleMenu(elem['option'])}
                        style={elem['status'] ? { color: "#029e55" } : { color: "black" }}
                    >
                        <img
                            src={elem['img']}
                            alt={`${elem['option']} icon`}
                        ></img>
                        {elem['option']}
                    </div>)
            }
        </nav >
    )
}
const RightSec = ({ menu, usersData, setUsersData }) => {
    const [email, setEmail] = useState('');
    const [date, setDate] = useState('');
    const [orderId, setOrderId] = useState('');
    const [asset, setAsset] = useState('');
    const [filteredData, setFilteredData] = useState(usersData)
    const handleEmail = (e) => {
        const text = e.target.value;
        setEmail(text);
    }
    const handleDate = (e) => {
        const text = e.target.value;
        setDate(text);
    }
    const handleOrderId = (e) => {
        const text = e.target.value;
        setOrderId(text);
    }
    const handleAsset = (e) => {
        const text = e.target.value;
        setAsset(text);
    }
    const handleOrderCancel = async (userId, orderId) => {
        const token = document.cookie.replace("ad_t=", "");
        const request = await fetch(
            "http://localhost:4000/api/admin/cancelOrders",
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ userId: userId, orderId: orderId }),
                withCredentials: true,
                credentials: 'include'
            }
        )
        const response = await request.json();
        setUsersData([...response]);
    }
    const handleOrderFilled = async (userId, orderId) => {
        const token = document.cookie.replace("ad_t=", "");
        const request = await fetch(
            "http://localhost:4000/api/admin/completedOrders",
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ userId: userId, orderId: orderId }),
                withCredentials: true,
                credentials: 'include'
            }
        )
        const response = await request.json();

        setUsersData([...response]);
    }
    useEffect(() => {
        
        //------finding resembling email ----//
        const emailFilter = usersData.filter(elem => elem['email'].includes(email));// eslint-disable-next-line 
        //-------finding data perDate -------//
        const finalArr = [];

        emailFilter.forEach((user) => {
            const row = [];
            user['order'].forEach((order) => {
                const { createdAt } = order;
                const { _id } = order;
                //-----conditions----//
                const dateC = createdAt.split("T")[0] >= date;
                const orderIdC = _id.includes(orderId);
                const assetC = order['asset'].includes(asset.toUpperCase())

                if (dateC && orderIdC && assetC) {
                    row.push(order)
                }
            })
            const newUser = { ...user };
            delete newUser.order;
            if (row.length > 0) finalArr.push({ ...newUser, order: [...row] })
        });
        setFilteredData([...finalArr])
        // eslint-disable-next-line 
    }, [email, date, orderId, asset]);

    

    return (
        <div className="RightSec">
            <DataWidget usersData={usersData} />

            {
                menu.map((elem, i) => {
                    if (elem['status']) {
                        switch (elem['option']) {
                            case 'Dashboard':
                                return <><SearchFields
                                    email={email}
                                    date={date}
                                    orderId={orderId}
                                    asset={asset}
                                    handleEmail={handleEmail}
                                    handleDate={handleDate}
                                    handleOrderId={handleOrderId}
                                    handleAsset={handleAsset} />
                                    <Userlist
                                        usersData={filteredData}
                                    /></>;

                            case 'Message':
                                return <Messages />;

                            case 'Pending Orders':
                                return <><SearchFields
                                    email={email}
                                    date={date}
                                    orderId={orderId}
                                    asset={asset}
                                    handleEmail={handleEmail}
                                    handleDate={handleDate}
                                    handleOrderId={handleOrderId}
                                    handleAsset={handleAsset} />
                                    <Userlist
                                        usersData={filteredData}
                                        orderStatus={'pending'}
                                        handleOrderCancel={handleOrderCancel}
                                        handleOrderFilled={handleOrderFilled} /></>;

                            case 'Completed Orders':
                                return <><SearchFields
                                    email={email}
                                    date={date}
                                    orderId={orderId}
                                    asset={asset}
                                    handleEmail={handleEmail}
                                    handleDate={handleDate}
                                    handleOrderId={handleOrderId}
                                    handleAsset={handleAsset} />
                                    <Userlist
                                        usersData={filteredData}
                                        orderStatus={'completed'}
                                        handleOrderCancel={handleOrderCancel}
                                        handleOrderFilled={handleOrderFilled} /></>;

                            default:
                                return <></>;
                        }
                    }else return <></>

                }
                )
            }
        </div>
    )
}
function Admin() {
    const { adminData } = useSelector((state) => state.admin);
    const navigate = useNavigate();
    //const dispatch = useDispatch();
    const [usersData, setUsersData] = useState('')
    const [menu, setMenu] = useState(
        [
            { option: 'Dashboard', status: true, img: dashBoard },
            { option: 'Message', status: false, img: message },
            { option: 'Pending Orders', status: false, img: OrderPending },
            { option: 'Completed Orders', status: false, img: completedOrder }
        ]
    );
    const handleMenu = (option) => {
        const newMenu = [...menu];
        newMenu.forEach((elem) => {
            elem['status'] = false;
            if (elem['option'] === option) elem['status'] = true;
        })
        setMenu(newMenu);
    }
    
    useEffect(() => {
        if (!adminData['adminId']) {
            navigate('/adminLogin')
        } else {
            //------------------------------//
            //     getting users details    //
            (async () => {
                const token = document.cookie.replace("ad_t=", "");
                const request = await fetch(
                    'http://localhost:4000/api/admin/usersDetails',
                    {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        withCredentials: true,
                        credentials: 'include'
                    }
                );
                const response = await request.json();
                setUsersData(response);
            })()

        }
    }, [adminData,navigate]);


    return (
        adminData['sessionAuthentication'] ?
            <div className="Admin">
                <LeftSec menu={menu} handleMenu={handleMenu} />
                {
                    usersData ?
                        <RightSec
                            menu={menu}
                            usersData={usersData}
                            setUsersData={setUsersData} />
                        :
                        <></>
                }
            </div > :
            <></>
    );
}

export default Admin;