import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import exclamationMark from '../svg/exclamationMark.svg'
import { LoadingRing, SuccessTransacCheck } from "../components/loadingButton/loadingbutton";
import VerificationCode from "../components/verificationCodeEntry/verificationCode";
const FormEmail = ({
    handleSubmit, handleEmail,
    email, err, loading, setLoading
}) => {
    return (
        <form onSubmit={handleSubmit} className={err ? "error emailSubm" : 'emailSubm'}>
            <input
                className={err ? "err" : ''}
                type="email"
                id="email"
                placeholder={err ? err : 'Enter your email'}
                onChange={handleEmail}
                value={err ? '' : email}
            />
            {
                loading ? <LoadingRing loading={{ loading: true }} />
                    : <button onClick={(e) => { setLoading(!loading); handleSubmit(e) }} >
                        Submit
                    </button>
            }
        </form>
    )
}
function ForgotPassword() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [err, setErr] = useState(null);
    const [access, setAccess] = useState(false);
    const [passChanged, setPassChanged] = useState(false);
    const [verifiCode, setVerifiCode] = useState([
        null, null, null, null, null, null
    ]);
    const handleVerifiCode = (e) => {
        const index = parseInt(e.target.id);
        let value = parseInt(e.target.value);
        const newArr = [...verifiCode];
        if (isNaN(value) && index > 0) {
            let leftField = document.getElementById((index - 1).toString());
            leftField.select();
        }
        if (!isNaN(value) && index < 5) {
            let rightField = document.getElementById((index + 1).toString());
            rightField.select();
        }
        if (index === 0 || index === 5) {
            if (value < 0) {
                value = 0
            }
        }
        if (index === 5) {
            if (value > 9) {
                value = value.toString();
                value = parseInt(value[0]);
            }
        }

        newArr[index] = value;
        setVerifiCode(newArr)
    }
    const [loading, setLoading] = useState(false);
    const handleEmail = (e) => {
        setErr('');
        setEmail(e.target.value);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const request = await fetch("http://localhost:4000/api/forgotpassword",
            {
                method: 'POST',
                body: JSON.stringify({ email: email }),
                headers: { 'Content-Type': 'application/json' }
            }
        );

        const response = await request.json();
        if (response['_id']) {
            setAccess(true);
            setLoading(false);
        } else {
            setEmail('');
            if (response['email']) setErr(response['email']);
            if (response['forgottenPassword']) setErr('service not availabe at the moment.');
            setLoading(false);
        }

    }
    const handleCodeSbm = async (e) => {
        e.preventDefault();
        const code = verifiCode.join('')
        const request = await fetch(
            'http://localhost:4000/api/forgotPasswordCodeReception',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    code: parseInt(code)
                })
            }
        )
        const response = await request.json()

        if (response['_id']) {
            setPassChanged(true)
            setLoading(false);
            setTimeout(() => {
                navigate('/signupLogin')
            }, 4000)
        } else {
            setLoading(false);
        }
    }
    return (
        <div className="ForgotPassword">
            <img src={exclamationMark} alt="Err icon"></img>
            <h1>Forgot Password</h1>
            {
                access ?
                    <p>Check your email to complete your password reset.</p>
                    :
                    <p>Enter your email and we'll send you a link to reset your password.</p>
            }
            {
                access ?
                    passChanged ?
                        <SuccessTransacCheck loading={{ loading: true }} />
                        : <VerificationCode
                            verifiCode={verifiCode}
                            handleVerifiCode={handleVerifiCode}
                            setLoading={setLoading}
                            loading={loading}
                            handleCodeSbm={handleCodeSbm}
                        />
                    : <FormEmail handleSubmit={handleSubmit} handleEmail={handleEmail}
                        email={email} err={err}
                        loading={loading} setLoading={setLoading}
                    />
            }
            <NavLink to="/signupLogin" className="backToLogin">
                <i><FontAwesomeIcon icon={faChevronLeft} /></i>
                <span>Back to Login</span>
            </NavLink>
        </div>
    );
}

export default ForgotPassword;