import { useEffect, useState } from "react";
import updownarrow from '../../svg/updownarrow.svg';
import PropTypes from 'prop-types';
import './CreateOrder.css';
import { SuccessTransacCheck, LoadingRing } from "../loadingButton/loadingbutton";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../redux/user";


const Asset = ({ assetClick, selectedCrypto, cryptoList, setSelectedCrypto, setAsset }) => {
    return (
        <label htmlFor="asset">
            Assets
            <div id='asset' onClick={() => setAsset(!assetClick)}>
                <div className='selectAsset'>
                    <div className='leftSide'>
                        <img src={selectedCrypto.image} alt={selectedCrypto.name + 'logo'}></img>
                        <span>{selectedCrypto.name}</span>
                    </div>

                    <img src={updownarrow} alt="up-down arrow"></img>
                </div>

                <div className='options'
                    style={assetClick ?
                        { display: 'block' } :
                        { display: 'none' }}>
                    {
                        cryptoList.map((crypto, index) =>
                            <div key={index.toString()}
                                onClick={() =>
                                    setSelectedCrypto({ name: crypto['name'], image: crypto['image'] })
                                } >
                                <img src={crypto.image} alt={`${crypto.name} logo`}></img>{crypto.name}
                            </div>
                        )
                    }
                </div>
            </div>
        </label>
    )
}
Asset.propTypes = {
    assetClick: PropTypes.bool.isRequired,
    selectedCrypto: PropTypes.shape(
        {
            name: PropTypes.string.isRequired,
            image: PropTypes.string.isRequired
        }
    ).isRequired,
    cryptoList: PropTypes.arrayOf(
        PropTypes.shape(
            {
                name: PropTypes.string.isRequired,
                image: PropTypes.string.isRequired
            }
        )
    ).isRequired,
    setSelectedCrypto: PropTypes.func.isRequired,
    setAsset: PropTypes.func.isRequired
}

const Networks = ({ network, setNetworkClick, networkList, networkClick, setNetwork }) => {
    return (
        <label htmlFor="network">
            Networks
            <div id='network' onClick={() => setNetworkClick(!networkClick)}>
                <div className='selectedNetwork'>
                    <span>{network.name}</span>
                    <img src={updownarrow} alt="up-down arrow"></img>
                </div>
                <div className='options' style={networkClick ?
                    { display: 'block' } :
                    { display: 'none' }}>
                    {
                        networkList.map((network, index) =>
                            <div key={index.toString()}
                                onClick={() => setNetwork({ name: network.name })}>
                                {network.name}
                            </div>
                        )
                    }
                </div>
            </div>
        </label>
    )
}
Networks.propTypes = {
    networkClick: PropTypes.bool.isRequired,
    network: PropTypes.shape(
        {
            name: PropTypes.string.isRequired
        }
    ).isRequired,
    networkList: PropTypes.arrayOf(
        PropTypes.shape(
            {
                name: PropTypes.string.isRequired
            }
        )
    ).isRequired,
    setNetworkClick: PropTypes.func.isRequired,
    setNetwork: PropTypes.func.isRequired
}

const WalletAddress = ({
    walletAddress,
    setWalletAddress, buySell, errors, setErrors
}) =>
    buySell ? <label className='addressFields'>
        Receiving Address
        <input
            id='addressField'
            type='text'
            value={walletAddress}
            className={
                errors['walletAddress'] ? 'errors' : ''
            }
            onChange={(e) => {
                setWalletAddress(e.target.value)
                if (errors['walletAddress']) {
                    let error = { ...errors };
                    error['walletAddress'] = '';
                    setErrors(error);
                }
            }}
            placeholder={errors['walletAddress'] ? errors['walletAddress'] : "Wallet Address"}

        ></input>
    </label> :
        <></>
WalletAddress.propTypes = {
    walletAddress: PropTypes.string.isRequired,
    setWalletAddress: PropTypes.func.isRequired,
    buySell: PropTypes.bool.isRequired
}

const CryptoFiat = ({ fiat, setFiat, selectedCrypto, crypto, setCrypto }) => {
    const [livePrice, setLivePrice] = useState(0);
    const cryptoName = selectedCrypto['name'].toLowerCase();
    useEffect(() => {
        if (cryptoName !== 'usdt') {

            const ws = new WebSocket(`wss://stream.binance.com:9443/ws/${cryptoName}usdt@ticker`);

            ws.onopen = () => {
                ws.send(JSON.stringify({ method: 'SUBSCRIBE', params: [`${cryptoName}usdt@ticker`], id: 1 }));
            };

            ws.onmessage = (data) => {
                const message = JSON.parse(data.data);
                const price = parseFloat(message.c).toFixed(2);
                if (!isNaN(price)) {
                    setLivePrice(price);
                    setCrypto(Number((fiat / price).toFixed(4)));
                } else setLivePrice('...');
            };

            return () => {
                ws.close();
            };
        } else { setLivePrice(1); setCrypto(Number(fiat)); }
    }, [selectedCrypto, fiat, setCrypto, cryptoName]);
    return (
        <div className='transacAmount'>
            <div className='cryptoEqui'>
                <span className='labelCrypt'>{selectedCrypto['name']}</span>
                <span className='cryptValue'>{crypto}</span>
            </div>
            <div className='currentRate'>
                <span className='labelFiat'>
                    <img src={selectedCrypto['image']} alt={`${selectedCrypto['name']}logo`}></img>{selectedCrypto['name']}/USD
                </span>
                <span className='fiatValue'>{livePrice}</span>
            </div>
            <label className='fiat' htmlFor='currencyField'>USD
                <input
                    id='currencyField'
                    type='number'
                    onChange={(e) => setFiat(Number(e.target.value))}
                    min={30}
                    value={fiat}
                ></input>
            </label>
        </div>
    )
}
CryptoFiat.propTypes = {
    fiat: PropTypes.number.isRequired,
    setFiat: PropTypes.func.isRequired,
    selectedCrypto: PropTypes.shape(
        {
            name: PropTypes.string.isRequired,
            image: PropTypes.string.isRequired
        }
    ).isRequired,
    crypto: PropTypes.number.isRequired
    , setCrypto: PropTypes.func.isRequired
}

const Email = ({ email, setEmail, errors, setErrors }) => {
    return (
        <label htmlFor="email" className="Email">
            Email
            <input id="email"
                type="email"
                className={errors['email'] ? "errors" : ""}
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value);
                    if (errors['email']) { const error = { ...errors }; error['email'] = ''; setErrors(error); }
                }}
                placeholder={errors['email'] ? errors['email'] : "Example@gmail.com"}
                required={true}
            >
            </input>
        </label>
    )
}
Email.propTypes = {
    email: PropTypes.string.isRequired,
    setEmail: PropTypes.func.isRequired
}




export const CreateOrder = ({
    cryptoList, networkList,
    buySell, setBuySell,
    network, setNetwork,
    selectedCrypto, setSelectedCrypto,
    walletAddress, setWalletAddress,
    fiat, setFiat,
    crypto, setCrypto,
    email, setEmail,
    emailFieldPresence
}) => {
    const user  = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();
    const handleSubmit = async (e) => {
        e.preventDefault()
        let userData = {
            email: email,
            order: {
                asset: selectedCrypto['name'],
                network: network['name'],
                fiat: fiat,
                crypto: crypto,
                walletAddress: walletAddress,
            }
        }
        const token = document.cookie.replace("jwt=", "");
        const request = await fetch(
            emailFieldPresence ?
                'http://localhost:4000/api/oneTimeTransation' :
                'http://localhost:4000/api/personalAreaPostOrder',
            emailFieldPresence ? {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(userData)
            } : {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                withCredentials: true,
                credentials: 'include',
                body: JSON.stringify(userData)
            }
        );
        setSubmission({
            subBtn: false,
            loadBtn: true,
            resBtn: false
        })
        const response = await request.json();
        
        if (response['_id']) {
            if (user) {
                dispatch(updateUser({...response}));
                console.log(user)
            }
            setSubmission({
                subBtn: false,
                loadBtn: false,
                resBtn: true
            });
            setTimeout(() => {
                setSubmission({
                    subBtn: true,
                    loadBtn: false,
                    resBtn: false
                });
            }, 5000);
        } else {
            console.log(response);
            setSubmission({
                loadBtn: false,
                subBtn: true,
                resBtn: false
            });

            for (let key in response) {
                if (response[key]) {
                    switch (key) {
                        case 'email':
                            setEmail('');
                            break;
                        case 'walletAddress':
                            setWalletAddress('');
                            break;
                        default:
                            break;
                    }
                }
            }
            setErrors(await response);
        }
    }


    const [errors, setErrors] = useState({
        email: '',
        order: {
            asset: '',
            network: '',
            fiat: '',
            crypto: '',
            walletAddress: '',
        }
    });
    const [assetClick, setAsset] = useState(false)
    const [networkClick, setNetworkClick] = useState(false);
    const [submission, setSubmission] = useState({
        subBtn: true,
        loadBtn: false,
        resBtn: false
    })
    return (
        <form className='CreateOrder' onSubmit={handleSubmit}>
            <div className='buy'
                style={buySell ? { backgroundColor: '#019852' } : { backgroundColor: 'white', color: '#3a3a3a' }}
                onClick={() => {
                    if (buySell !== true)
                        setBuySell(!buySell);
                }}>Buy</div>
            <div className='sell'
                style={!buySell ? { backgroundColor: '#f33939' } : { backgroundColor: 'white', color: '#3a3a3a' }}
                onClick={() => {
                    if (buySell !== false)
                        setBuySell(!buySell);
                }}>Sell</div>
            <Asset assetClick={assetClick} selectedCrypto={selectedCrypto} cryptoList={cryptoList} setSelectedCrypto={setSelectedCrypto} setAsset={setAsset} />
            <Networks network={network} setNetworkClick={setNetworkClick} networkList={networkList} networkClick={networkClick} setNetwork={setNetwork} />
            <WalletAddress walletAddress={walletAddress} setWalletAddress={setWalletAddress} buySell={buySell} errors={errors} setErrors={setErrors} />
            <CryptoFiat fiat={fiat} setFiat={setFiat} selectedCrypto={selectedCrypto} crypto={crypto} setCrypto={setCrypto} errors={errors} />
            {
                emailFieldPresence ?
                    buySell ? <Email email={email} setEmail={setEmail} errors={errors} setErrors={setErrors} /> : <></> :
                    <></>
            }
            {
                submission['subBtn'] ?
                    <button
                        className='confirmBtn'
                        onClick={() => {
                            let newVal = { ...submission };
                            newVal['subBtn'] = true;
                            newVal['loadBtn'] = false;
                            setSubmission(newVal);
                        }}
                        style={buySell ? { backgroundColor: '#019852', color: 'white' } :
                            { backgroundColor: '#f33939', color: 'white' }}
                    >Confirm
                    </button> : <></>
            }
            <LoadingRing loading={submission['loadBtn']} buySell={buySell} />
            <SuccessTransacCheck loading={submission['resBtn']} buySell={buySell} />

        </form>
    )
}

CreateOrder.propTypes = {
    cryptoList: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            image: PropTypes.string.isRequired
        })
    ),
    networkList: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired
        })
    ),
    buySell: PropTypes.bool.isRequired,
    setBuySell: PropTypes.func.isRequired,
    network: PropTypes.shape({
        name: PropTypes.string.isRequired
    }),
    setNetwork: PropTypes.func.isRequired,
    selectedCrypto: PropTypes.shape({
        name: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired
    }),
    setSelectedCrypto: PropTypes.func.isRequired,
    walletAddress: PropTypes.string.isRequired,
    setWalletAddress: PropTypes.func.isRequired,
    fiat: PropTypes.number.isRequired,
    setFiat: PropTypes.func.isRequired,
    email: PropTypes.string,
    setEmail: PropTypes.func,
    emailFieldPresence: PropTypes.bool.isRequired,
    crypto: PropTypes.number.isRequired
    , setCrypto: PropTypes.func.isRequired
}