import { useEffect, useState } from 'react';
import BTC from '../svg/btc.svg';
import ETH from '../svg/eth.svg';
import XRP from '../svg/xrp.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { cryptoList, networkList } from '../devResources/homeR.js';
import { CreateOrder } from '../components/CreateOrder/CreateOrder';

const ShortDescription = () => {
    return (
        <div className="Short-description">
            <span>Join our community</span>
            <h1>Our goal is to make it simple for Burundians to acquire digital assets.</h1>
            <p>BICrypto will soon be released. A cryptocurrency created by and for Burundians. Sign up for our mailing list to remain updated.</p>
            <div className="email">
                <input
                    placeholder="Enter your Email"
                    type="text"
                >
                </input>
                <button>Try it</button>
            </div>
        </div>
    )

}
const LivePriceDisplay = () => {
    const [btc, setBtc] = useState({
        Price: 0,
        Change: 0,
        Volume: 0
    });
    const [eth, setEth] = useState({
        Price: 0,
        Change: 0,
        Volume: 0
    });
    const [xrp, setXrp] = useState({
        Price: 0,
        Change: 0,
        Volume: 0
    });

    useEffect(() => {
        const ws = new WebSocket('wss://stream.binance.com:9443/ws/btcusdt@ticker');
        ws.onopen = () => {
            ws.send(JSON.stringify({ method: 'SUBSCRIBE', params: [`btcusdt@ticker`], id: 1 }));
        };
        ws.onmessage = (data) => {
            const message = JSON.parse(data.data);
            setBtc({
                Price: parseFloat(message.c).toFixed(2),
                Change: parseFloat(message.P).toFixed(2),
                Volume: parseFloat(message.v).toFixed(2)
            })
        };

        return () => {
            ws.close();
        };
    }, []);

    useEffect(() => {
        const ws = new WebSocket('wss://stream.binance.com:9443/ws/ethusdt@ticker');
        ws.onopen = () => {
            ws.send(JSON.stringify({ method: 'SUBSCRIBE', params: [`ethusdt@ticker`], id: 1 }));
        };
        ws.onmessage = (data) => {
            const message = JSON.parse(data.data);
            setEth({
                Price: parseFloat(message.c).toFixed(2),
                Change: parseFloat(message.P).toFixed(2),
                Volume: parseFloat(message.v).toFixed(2)
            })
        };

        return () => {
            ws.close();
        };
    }, []);
    useEffect(() => {
        const ws = new WebSocket('wss://stream.binance.com:9443/ws/xrpusdt@ticker');
        ws.onopen = () => {
            ws.send(JSON.stringify({ method: 'SUBSCRIBE', params: [`xrpusdt@ticker`], id: 1 }));
        };
        ws.onmessage = (data) => {
            const message = JSON.parse(data.data);
            setXrp({
                Price: parseFloat(message.c).toFixed(2),
                Change: parseFloat(message.P).toFixed(2),
                Volume: parseFloat(message.v).toFixed(2)
            })
        };

        return () => {
            ws.close();
        };
    }, []);

    return (
        <div className='LivePriceDisplay'>
            {[{
                image: BTC,
                name: 'BTC',
                price: btc.Price,
                volume: btc.Volume,
                change: btc.Change
            }, {
                image: ETH,
                name: 'ETH',
                price: eth.Price,
                volume: eth.Volume,
                change: eth.Change
            }, {
                image: XRP,
                name: 'XRP',
                price: xrp.Price,
                volume: xrp.Volume,
                change: xrp.Change
            }].map((elm, index) =>
                <div className='crypto' key={index.toString()}>
                    <img src={elm.image} alt='crypto logo'></img>
                    <div className='cryptoInfo'>
                        <span className='Title'>{elm.name}/USD</span>
                        <span className='value'>$ {elm.price} </span>
                    </div>
                    <div className='cryptoInfo'>
                        <span className='Title'>Change</span>
                        <span className='value'><i><FontAwesomeIcon icon={faChevronUp} /></i> {elm.change} %</span>
                    </div>
                    <div className='cryptoInfo'>
                        <span className='Title'>Volume</span>
                        <span className='value'>$ {elm.volume} </span>
                    </div>


                </div>)}
        </div>
    )
}
function Home() {
    const [buySell, setBuySell] = useState(true);
    const [network, setNetwork] = useState({
        name: 'BNB Smart Chain (BEP20)'
    });
    const [selectedCrypto, setSelectedCrypto] = useState({
        name: cryptoList[0].name,
        image: cryptoList[0].image
    });
    const [fiat, setFiat] = useState(30);
    const [crypto, setCrypto] = useState(0);
    const [email, setEmail] = useState('');
    const [walletAddress, setWalletAddress] = useState('');

    return (
        <div className="Home">
            <div className='upperPart'>
                <ShortDescription />
                <CreateOrder
                    cryptoList={cryptoList} networkList={networkList}
                    network={network} setNetwork={setNetwork}
                    selectedCrypto={selectedCrypto} setSelectedCrypto={setSelectedCrypto}
                    walletAddress={walletAddress} setWalletAddress={setWalletAddress}
                    buySell={buySell} setBuySell={setBuySell}
                    fiat={fiat} setFiat={setFiat}
                    crypto={crypto} setCrypto={setCrypto}
                    email={email} setEmail={setEmail}
                    emailFieldPresence={true} />
            </div>

            <LivePriceDisplay />
        </div >
    );
}

export default Home;