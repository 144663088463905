import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user.js';
import accountVerifyReducer from './userVerification.js';
import adminReducer from './admin.js';

const store = configureStore(
    {
        reducer: {
            user: userReducer,
            accountVerify: accountVerifyReducer,
            admin: adminReducer
        }
    }
);

export default store; 