import { createSlice } from "@reduxjs/toolkit";

export const userVerifySlice = createSlice(
    {
        name: 'accountVerify',
        initialState: {
            email: null
        },
        reducers: {
            updateEmail: (state, action) => {
                state.email = action.payload
            }
        }
    }
);

export const { updateEmail } = userVerifySlice.actions;
export default userVerifySlice.reducer;