import './verificationCode.css';
import { LoadingRing } from '../loadingButton/loadingbutton';

function VerificationCode({
    verifiCode, handleVerifiCode,
    setLoading, loading,
    handleCodeSbm }) {

    return (
        <form className='verificationCodeComp'>

            <div className="inputFieldsCode" >
                {
                    verifiCode.map(
                        (digit, i) =>
                            <input
                                type="number"
                                className="inputField"
                                id={i.toString()}
                                key={i.toString()}
                                placeholder="0"
                                onChange={handleVerifiCode}
                                min={0}
                                max={9}
                                value={!isNaN(digit) ? digit : ''}
                            >
                            </input>
                    )
                }
            </div>
            {
                loading ? <LoadingRing loading={{ loading: true }} />
                    : <button onClick={(e) => { setLoading(true); handleCodeSbm(e) }} >
                        Submit
                    </button>
            }
        </form>
    );
}

export default VerificationCode;