import { Order } from '../components/Order/Order';
import message from '../png/messageIcon.png';
import historyIcon from '../png/historyOrder.png';
import pendingOrder from '../png/pendingOrder.png';
import cancelledOrder from '../png/cancelledOrder.png';
import { LeftSideMenu } from '../components/LeftSideMenu/LeftSideMenu';
import { CreateOrder } from '../components/CreateOrder/CreateOrder';
import { FiltersForOrders } from '../components/FiltersForOrders/FiltersForOrders';
import { MessagesSec } from '../components/MessagesSec/MessagesSec';
import completedOrder from '../png/completedOrder.png';
import creatingOrder from '../png/creatingOrder.png';
import { messages, networkList, cryptoList } from '../devResources/personalareaR';
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { LoadingRing } from '../components/loadingButton/loadingbutton';

import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../redux/user.js';

const Pendingorders = ({ orders, option, handleCanceledOrder }) =>
    <div className='pendingOrders Orders'>
        {
            orders.filter((order) => order['status'] === 'pending')
                .map((order, index) => (
                    <Order
                        key={index.toString()}
                        order={order}
                        option={option}
                        index={index}
                        handleCanceledOrder={handleCanceledOrder}
                    />
                ))
        }
    </div>
const Canceledorders = ({ orders, option }) => {
    return (
        <div className='cancelledorders Orders'>
            {
                orders.filter((order) => order['status'] === 'cancelled')
                    .map((order, index) => (
                        <Order
                            key={index.toString()}
                            order={order}
                            option={option}
                            index={index}
                        />
                    ))
            }
        </div>
    )
}
const OrderHistory = ({ orders, option }) =>
    <div className="Orderhistory Orders">
        {
            orders.filter((order) => order['status'] !== 'pending')
                .map((order, index) => (
                    <Order
                        key={index.toString()}
                        order={order}
                        option={option}
                        index={index}
                    />
                ))
        }
    </div>
const CompletedOrder = ({ orders, option }) =>
    <div className="CompletedOrder Orders">
        {
            orders.filter((order) => order['status'] === 'completed')
                .map((order, index) => (
                    <Order
                        key={index.toString()}
                        order={order}
                        option={option}
                        index={index}
                    />
                ))
        }
    </div>
const MainPart = ({
    orders = [{
        asset: "btc", network: "EOS", walletAddress: "dvasdvasd", crypto: 0.0011, fiat: 30, status: "completed", _id: "6475c8da04f9f7df24890e11", createdAt: "2023-05-30T09:58:50.044Z",
        updatedAt: "2023-05-30T09:58:50.044Z"
    }],
    menuOptions, messages,
    networkList, cryptoList,
    network, setNetwork,
    selectedCrypto, setSelectedCrypto,
    walletAddress, setWalletAddress,
    buySell, setBuySell,
    fiat, setFiat,
    crypto, setCrypto,
    email, handleCanceledOrder
}) => {
    let elmDisplay;
    menuOptions.forEach((option, i) => {
        if (option['status']) {
            switch (option['name']) {
                case 'Pending Order':
                    elmDisplay = <Pendingorders orders={orders} option={menuOptions[i]} handleCanceledOrder={handleCanceledOrder} />;
                    break;
                case 'Order History':
                    elmDisplay = <OrderHistory orders={orders} option={menuOptions[i]} />;
                    break;
                case 'Messages':
                    elmDisplay = <MessagesSec messages={messages} />;
                    break;
                case 'Canceled orders':
                    elmDisplay = <Canceledorders orders={orders} option={menuOptions[i]} />;
                    break;
                case 'Completed Order':
                    elmDisplay = <CompletedOrder orders={orders} option={menuOptions[i]} />;
                    break;
                case 'Create Order':
                    elmDisplay = <CreateOrder
                        cryptoList={cryptoList} networkList={networkList}
                        network={network} setNetwork={setNetwork}
                        selectedCrypto={selectedCrypto} setSelectedCrypto={setSelectedCrypto}
                        walletAddress={walletAddress} setWalletAddress={setWalletAddress}
                        buySell={buySell} setBuySell={setBuySell}
                        fiat={fiat} setFiat={setFiat}
                        crypto={crypto} setCrypto={setCrypto}
                        emailFieldPresence={false} email={email} />;
                    break;
                default:
                    return 'option not registered.'
            }
        }

    });
    return (
        <div className="MainPart">
            {
                elmDisplay
            }
        </div>
    )
}
function PersonalArea() {
    const [userAuthenticated, setUserAuthenticated] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const userData = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();
    useEffect(() => {
        (
            async () => {
                const token = document.cookie.replace("jwt=", "");
                const request = await fetch(
                    'http://localhost:4000/api/personalArea/' + id,// eslint-disable-next-line 
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        withCredentials: true,
                        credentials: 'include'
                    }
                );
                const response = await request.json();
                if (response['_id']) {
                    setUserAuthenticated(true);
                    if (userData !== response) dispatch(updateUser({ ...response }));// eslint-disable-next-line 
                } else navigate("/signupLogin");// eslint-disable-next-line 
            })();
            // eslint-disable-next-line 
    }, []);
    //----------------------------------------------------//
    //----------------------------------------------------//
    const [menuOptions, setOptions] = useState([
        {
            icon: pendingOrder,
            name: 'Pending Order',
            status: false
        }
        ,
        {
            icon: historyIcon,
            name: 'Order History',
            status: true
        }
        ,
        {
            icon: message,
            name: 'Messages',
            status: false
        }
        ,
        {
            icon: cancelledOrder,
            name: 'Canceled orders',
            status: false
        }
        ,
        {
            icon: completedOrder,
            name: 'Completed Order',
            status: false
        }
        ,
        {
            icon: creatingOrder,
            name: 'Create Order',
            status: false
        }
    ]);
    const handleOptions = (e) => {
        const name = e.target.innerText;
        const newArr = menuOptions.map(elm => {
            elm.status = false;
            return elm;
        });
        const index = menuOptions.findIndex(object => {
            return object.name === name;
        });
        newArr[index]['status'] = true;
        setOptions(newArr);
    };
    const [network, setNetwork] = useState({
        name: 'BNB Smart Chain (BEP20)'
    });
    const [selectedCrypto, setSelectedCrypto] = useState({
        name: cryptoList[0].name,
        image: cryptoList[0].image
    });
    const [walletAddress, setWalletAddress] = useState('');
    const [buySell, setBuySell] = useState(true);
    const [fiat, setFiat] = useState(30);
    const [crypto, setCrypto] = useState(0);
    //------------------------------------------------------
    //State and functions primarly used for filter component.
    //------------------------------------------------------
    // State to track the selected date range
    const [dateRange, setDateRange] = useState({
        startDate: '', // The start date of the selected date range
        endDate: '' // The end date of the selected date range
    });
    // Function to handle changes to the date range
    const handleDateRange = (e) => {
        let date = {
            startDate: '',
            endDate: ''
        };
        if (e.target.id === 'from') {
            // If the 'from' date input is changed, update the start date of the date range
            date.startDate = e.target.value.replace(/-/g, "/");
            date.endDate = dateRange.endDate;
        } else {
            // If the 'to' date input is changed, update the end date of the date range
            date.endDate = e.target.value.replace(/-/g, "/");
            date.startDate = dateRange.startDate;
        }
        // Update the state with the new date range
        setDateRange(date);
    };
    // State to track the sorting order
    const [ascDesc, setAscDesc] = useState(true); // true for ascending, false for descending    // Function to handle changes to the sorting order
    const handleAscDesc = (val) => {
        // If the current sorting order is 'desc' and the user clicks 'asc', or vice versa, toggle the sorting order
        if ((val === 'Asc' && ascDesc === false) || (val === 'Desc' && ascDesc === true)) {
            setAscDesc(!ascDesc);
        }
    };
    // State to track which cryptocurrencies are being filtered
    const [cryptoCurrencies, setCryptoCurrencies] = useState({
        Bitcoin: true,
        Ethereum: true,
        Ripple: true,
        Tether: true,
        Cardona: true,
        Chainlink: true
    });
    // Function to handle changes to the cryptocurrency filter
    const handleCrypto = (value) => {
        let switcher = { ...cryptoCurrencies };
        // Toggle the state of the selected cryptocurrency
        switcher[value] = !cryptoCurrencies[value];
        setCryptoCurrencies(switcher);
    };

    // State to track the selected price range
    const [priceRange, setPriceRange] = useState({
        min: 30, // The minimum price in the selected price range
        max: 1000000 // The maximum price in the selected price range
    });
    // Function to handle changes to the price range
    const handlePriceRange = (e) => {
        if (e.target.id === 'Min') {
            // If the 'min' price input is changed, update the minimum price in the price range
            setPriceRange({
                min: parseFloat(e.target.value),
                max: priceRange.max
            });
        } else if (e.target.id === 'Max') {
            // If the 'max' price input is changed, update the maximum price in the price range
            setPriceRange({
                min: priceRange.min,
                max: parseFloat(e.target.value)
            });
        }
    };
    const dateFormat = (date) => date.slice(0, date.indexOf('T')).replace(/-/g, '/');

    const [filteredData, setFilteredData] = useState('');
    useEffect(() => {
        let newOrderlist = [];
        let orderFromDB = [...userData['order']]
        const { startDate, endDate } = dateRange;
        //Sorting per date range

        if (startDate || endDate) {
            if (endDate) {
                newOrderlist = orderFromDB
                    .filter(elem =>
                        dateFormat(elem['createdAt']) <= endDate);
                if (startDate) {
                    newOrderlist = newOrderlist
                        .filter(elem =>
                            dateFormat(elem['createdAt']) >= startDate
                        );
                }
            } else {
                newOrderlist = orderFromDB
                    .filter(elem =>
                        dateFormat(elem['date']) >= startDate
                    );
            }
        } else newOrderlist = [...orderFromDB];

        //Sorting per order value
        if (!ascDesc)
            newOrderlist = newOrderlist.sort((a, b) => a['fiat'] - b['fiat']);
        else newOrderlist = newOrderlist.sort((a, b) => b['fiat'] - a['fiat']);
        //Sorting per crypto choice
        let crytpoChoosen = []
        for (let key in cryptoCurrencies) {
            if (cryptoCurrencies[key]) {
                switch (key) {
                    case 'Bitcoin':
                        crytpoChoosen.push('BTC')
                        break;
                    case 'Ethereum':
                        crytpoChoosen.push('ETH')
                        break;
                    case 'Ripple':
                        crytpoChoosen.push('XRP')
                        break;
                    case 'Tether':
                        crytpoChoosen.push('USDT')
                        break;
                    case 'Cardona':
                        crytpoChoosen.push('ADA')
                        break;
                    case 'Chainlink':
                        crytpoChoosen.push('LINK')
                        break;
                    default:
                        break;
                }

            };
        }
        newOrderlist = newOrderlist.filter(elem => crytpoChoosen.includes(elem['asset']));
        //Sorting per price range   
        newOrderlist = newOrderlist.filter(elem => elem['fiat'] >= priceRange.min && elem['fiat'] <= priceRange.max)

        setFilteredData(newOrderlist);

    }, [dateRange, ascDesc, cryptoCurrencies, priceRange, userData]);
    const handleReset = () => {
        setCrypto({
            Bitcoin: true,
            Ethereum: true,
            Ripple: true,
            Tether: true,
            Cardona: true,
            Chainlink: true
        });
        setAscDesc(true);
        setDateRange({
            startDate: '', endDate: ''
        });
        setPriceRange({
            min: 30,
            max: 10000
        });
    };
    //-------------------------------------------------------
    //-------------------------------------------------------
    const handleCanceledOrder = async (orderId) => {
        const token = document.cookie.replace('jwt=', '')
        const request = await fetch(
            'http://localhost:4000/api/personalAreaOrderDeletion',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                withCredentials: true,
                credentials: 'include',
                body: JSON.stringify({ userID: userData['_id'], orderId: orderId })
            }
        );
        const response = await request.json();
        if (response['_id']) {
            dispatch(updateUser(response));
        } else {
            console.log(response)
        }
    }


    return (
        <div className="personalArea">
            {userAuthenticated && userData !== null && userData['userVerified'] ? <>
                <LeftSideMenu
                    menuOptions={menuOptions}
                    handleOptions={handleOptions}
                />
                <MainPart
                    orders={filteredData}
                    menuOptions={menuOptions}
                    messages={messages}
                    cryptoList={cryptoList}
                    networkList={networkList}
                    network={network}
                    selectedCrypto={selectedCrypto}
                    setSelectedCrypto={setSelectedCrypto}
                    setNetwork={setNetwork}
                    walletAddress={walletAddress} setWalletAddress={setWalletAddress}
                    buySell={buySell} setBuySell={setBuySell}
                    crypto={crypto} setCrypto={setCrypto}
                    fiat={fiat} setFiat={setFiat}
                    email={userData['email']}
                    handleCanceledOrder={handleCanceledOrder}
                />

                {
                    !menuOptions[2]['status'] && !menuOptions[5]['status'] ?
                        <FiltersForOrders
                            dateRange={dateRange}
                            ascDesc={ascDesc}
                            cryptoCurrencies={cryptoCurrencies}
                            priceRange={priceRange}
                            handleAscDesc={handleAscDesc}
                            handleCrypto={handleCrypto}
                            handlePriceRange={handlePriceRange}
                            handleDateRange={handleDateRange}
                            handleReset={handleReset}

                        /> : <></>
                }
            </>
                : <div className='loader'>
                    <LoadingRing loading={true} />
                </div>}
        </div>
    );
}

export default PersonalArea;