import BTC from '../svg/btc.svg';
import USDT from '../svg/tether.svg';
import ETH from '../svg/eth.svg';
import LINK from '../svg/Link.svg';
import XRP from '../svg/xrp.svg';
import ADA from '../svg/Ada.svg';
export const cryptoList = [
    { name: 'USDT', image: USDT },
    { name: 'BTC', image: BTC },
    { name: 'ETH', image: ETH },
    { name: 'LINK', image: LINK },
    { name: 'XRP', image: XRP },
    { name: 'ADA', image: ADA },
];
export const networkList = [
    { name: 'BNB Smart Chain (BEP20)' },
    { name: 'EOS' },
    { name: 'AVAX C-Chain' },
    { name: 'BNB Beacon Chain (BEP20)' },
    { name: 'Ethereum (ERC20)' },
    { name: 'Polygon' },
    { name: 'Solana' },
    { name: 'Tezos' },
    { name: 'Tron (TRC20)' }
];
export const ordersHistory = [
    {
        id: 'asdf31d21',
        date: '2023/05/01',
        name: 'Bitcoin',
        img: BTC,
        amount: 1.3,
        usd: 200,
        status: 'pending'

    }
    ,
    {
        id: 'asdf31d21',
        date: '2023/05/02',
        name: 'Ethereum',
        img: ETH,
        amount: 2.3,
        usd: 2000,
        status: 'cancelled'
    }
    ,
    {
        id: 'asdf31d21',
        date: '2023/05/03',
        name: 'Tether',
        img: USDT,
        amount: 1200,
        usd: 1200,
        status: 'pending'
    }
    ,
    {
        id: 'asdf31d21',
        date: '2023/05/04',
        name: 'Chainlink',
        img: LINK,
        amount: 0.1,
        usd: 45,
        status: 'cancelled'
    }
    ,
    {
        id: 'asdf31d21',
        date: '2023/05/05',
        name: 'Ripple',
        img: XRP,
        amount: 100,
        usd: 32,
        status: 'pending'
    }
    ,
    {
        id: 'asdf31d21',
        date: '2023/05/06',
        name: 'Cardona',
        img: ADA,
        amount: 200,
        usd: 50,
        status: 'cancelled'
    }
    ,
    {
        id: 'asdf31d21',
        date: '2023/05/07',
        name: 'Chainlink',
        img: LINK,
        amount: 0.1,
        usd: 64,
        status: 'cancelled'
    }
    ,
    {
        id: 'asdf31d21',
        date: '2023/05/08',
        name: 'Ripple',
        img: XRP,
        amount: 100,
        usd: 42,
        status: 'completed'
    }
    ,
    {
        id: 'asdf31d21',
        date: '2023/05/09',
        name: 'Cardona',
        img: ADA,
        amount: 200,
        usd: 50,
        status: 'pending'
    }
    ,
    {
        id: 'asdf31d21',
        date: '2023/05/10',
        name: 'Ethereum',
        img: ETH,
        amount: 2.3,
        usd: 20000,
        status: 'completed'
    }
    ,
    {
        id: 'asdf31d21',
        date: '2023/05/11',
        name: 'Tether',
        img: USDT,
        amount: 1200,
        usd: 1200,
        status: 'completed'
    }
    ,
    {
        id: 'asdf31d21',
        date: '2023/05/12',
        name: 'Chainlink',
        img: LINK,
        amount: 0.1,
        usd: 62,
        status: 'cancelled'
    }
    ,
    {
        id: 'asdf31d21',
        date: '2023/05/13',
        name: 'Ripple',
        img: XRP,
        amount: 100,
        usd: 20,
        status: 'completed'
    }
    ,
    {
        id: 'asdf31d21',
        date: '2023/05/14',
        name: 'Cardona',
        img: ADA,
        amount: 200,
        usd: 50,
        status: 'completed'
    }
    ,
    {
        id: 'asdf31d21',
        date: '2023/05/15',
        name: 'LINK',
        img: LINK,
        amount: 0.1,
        usd: 83,
        status: 'completed'
    }
    ,
    {
        id: 'asdf31d21',
        date: '2023/05/16',
        name: 'Ripple',
        img: XRP,
        amount: 100,
        usd: 45,
        status: 'completed'
    }
    ,
    {
        id: 'asdf31d21',
        date: '2023/05/17',
        name: 'Cardona',
        img: ADA,
        amount: 200,
        usd: 50,
        status: 'cancelled'
    }
];
export const messages = [
    {
        date: '01/02/2003',
        messages: [
            {
                description: 'USDT order',
                title: 'Order number #23902',
                image:USDT,
                time:'11:52 AM'
            },
            {
                description: 'USDT order',
                title: 'Order number #23902',
                image:USDT,
                time:'11:52 AM'
            },
            {
                description: 'USDT order',
                title: 'Order number #23902',
                image:USDT,
                time:'11:52 AM'
            }
        ]
    }, {
        date: '01/02/2003',
        messages: [
            {
                description: 'USDT order',
                title: 'Order number #23902',
                image:USDT,
                time:'11:52 AM'
            },
            {
                description: 'USDT order',
                title: 'Order number #23902',
                image:USDT,
                time:'11:52 AM'
            },
            {
                description: 'USDT order',
                title: 'Order number #23902',
                image:USDT,
                time:'11:52 AM'
            }
        ]
    }
];