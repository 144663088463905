import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { LoadingRing } from "../components/loadingButton/loadingbutton";
import { useDispatch } from 'react-redux';
import { updateEmail } from '../redux/userVerification';
import { updateUser } from '../redux/user'
import VerificationCode from "../components/verificationCodeEntry/verificationCode";
import Timer from "../components/Timer/Timer.js";

const SubmitBtn = ({ loginSignup, handleSubmit, setLoading, loading }) => {
    return (
        loading ? <LoadingRing loading={true} />
            : <button onClick={(e) => { handleSubmit(e); setLoading(!loading) }} >
                {loginSignup ? "Sign Up" : "Login"}
            </button>
    )
}
const InputFields = ({ errors, handlePassword, handleEmail, userData }) => <>
    <div className={"email" in errors ? "errors" : ""}>
        <input
            type="text"
            placeholder={'email' in errors ? errors.email : "Enter you email."}
            onChange={handleEmail}
            value={userData['email']}
        >
        </input>
    </div>
    <div className={'password' in errors ? "errors" : ""}>
        <input
            type="Password"
            placeholder={'password' in errors ? errors.password : "Enter your password."}
            onChange={handlePassword}
            value={userData['password']}
        >
        </input>
    </div>
</>
const Login = ({ loginSignup, handleLogin, handleEmail, handlePassword, errors, loading, setLoading, userData }) => {
    return (
        <div id="login" className="login">
            <h1>Login</h1>
            <InputFields errors={errors} handlePassword={handlePassword} handleEmail={handleEmail} userData={userData} />
            <NavLink style={{ textDecoration: 'none', color: 'rgba(54, 54, 54)' }} to="forgotPassword">Forgot Password?</NavLink>
            <SubmitBtn loginSignup={loginSignup} handleSubmit={handleLogin} setLoading={setLoading} loading={loading} />
        </div>
    )
}
const Signup = ({ loginSignup, handleSignup, handleEmail, handlePassword, errors, userData, loading, setLoading }) => {
    return (
        <div id="signup" className="signup">
            <h1>Create Account</h1>
            <InputFields errors={errors} handlePassword={handlePassword} handleEmail={handleEmail} userData={userData} />
            <SubmitBtn loginSignup={loginSignup} handleSubmit={handleSignup} setLoading={setLoading} loading={loading} />
        </div>
    )
}


function LoginSignup() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loginSignup, setLoginSignup] = useState(true);
    const [loading, setLoading] = useState(false);
    const [time, setTimer] = useState({
        min: 1,
        sec: 59
    })
    const handleTimer = (val) => { setTimer(val); }
    const [accountExist, setAccountExist] = useState(false);
    const [verifiCode, setVerifiCode] = useState([
        null, null, null, null, null, null
    ]);
    const [userData, setUserData] = useState({ email: '', password: '' });
    const [errors, setErrors] = useState({});
    const handleVerifiCode = (e) => {
        const index = parseInt(e.target.id);
        let value = parseInt(e.target.value);
        const newArr = [...verifiCode];
        if (isNaN(value) && index > 0) {
            let leftField = document.getElementById((index - 1).toString());
            leftField.select();
        }
        if (!isNaN(value) && index < 5) {
            let rightField = document.getElementById((index + 1).toString());
            rightField.select();
        }
        if (index === 0 || index === 5) {
            if (value < 0) {
                value = 0
            }
        }
        if (index === 5) {
            if (value > 9) {
                value = value.toString();
                value = parseInt(value[0]);
            }
        }

        newArr[index] = value;
        setVerifiCode(newArr)
        setErrors({});
    }
    const handleEmail = (e) => {
        let error = errors;
        delete error.email;
        setErrors(error);
        setUserData({ email: e.target.value, password: userData.password });
    }
    const handlePassword = (e) => {
        let error = errors;
        delete error.password;
        setErrors(error);
        setUserData({ email: userData.email, password: e.target.value })
    }
    const formTransitions = (borderRadius, welcometextPos, formPos) => {
        const inputSec = document.getElementById('welcomingMessage');
        inputSec.style.transition = "all 2s";
        inputSec.style.left = inputSec.offsetLeft + welcometextPos + "px";
        inputSec.style.borderBottomLeftRadius = borderRadius.left;
        inputSec.style.borderTopLeftRadius = borderRadius.left;
        inputSec.style.borderBottomRightRadius = borderRadius.right;
        inputSec.style.borderTopRightRadius = borderRadius.right;
        inputSec.style.zIndex = 1;

    }
    const handleSignupPos = () => {
        formTransitions({
            left: '0px', right: '32px'
        }, 320, 480);
        setErrors({});
        setUserData({ email: '', password: '' });
        setVerifiCode([null, null, null, null, null, null]);
        handleTimer({
            min: 1,
            sec: 59
        })
    }
    const handleLoginPos = () => {
        formTransitions({
            left: '32px', right: '0px'
        }, -320, -480);
        setErrors({});
        setUserData({ email: '', password: '' });
        setVerifiCode([null, null, null, null, null, null]);
        handleTimer({
            min: 1,
            sec: 59
        })
    }
    const handleResendLoginCode = async (e) => {
        setVerifiCode([null, null, null, null, null, null]);
        handleTimer({
            min: 1,
            sec: 59
        })
        await fetch(
            'http://localhost:4000/api/resendLoginCode',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: userData['email'] })
            }
        );
    }
    const handleLogin = async (e) => {
        e.preventDefault()
        const request = await fetch(
            "http://localhost:4000/api/login", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true, credentials: 'include',
            body: JSON.stringify(userData)
        });
        const response = await request.json();
        if (response['_id']) {
            setTimeout(() => {
                setLoading(false);
                setAccountExist(true);
            }, 3000);
        } else {
            let errors = {};
            let userdata = {
                email: userData.email,
                password: userData.password
            }
            Object.keys(response).forEach(val => {
                if (response[val] !== '') {
                    errors[val] = response[val];
                    userdata[val] = ''
                }
            });
            setLoading(false);
            setErrors(errors);
            setUserData(userdata);
        }
    }
    const handleSignup = async (e) => {
        e.preventDefault()
        const request = await fetch(
            "http://localhost:4000/api/signup", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }, withCredentials: true, credentials: 'include',
            body: JSON.stringify(userData)
        })
        const response = await request.json();
        if (response['_id']) {
            dispatch(updateEmail(response['email']));
            setTimeout(() => {
                setLoading(false);
                navigate("/signupLogin/signedUp");
            }, 3000);
        } else {
            let errors = {};
            let userdata = {
                email: userData.email,
                password: userData.password
            }
            Object.keys(response).forEach(val => {
                if (response[val] !== '') {
                    errors[val] = response[val];
                    userdata[val] = ''
                }
            });
            setLoading(false);
            setErrors(errors);
            setUserData(userdata);
        }
    }
    const handleCodeSbm = async (e) => {
        e.preventDefault();
        const code = verifiCode.join('');
        const request = await fetch(
            'http://localhost:4000/api/loginCode',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }, withCredentials: true, credentials: 'include',
                body: JSON.stringify({
                    email: userData['email'],
                    code: code
                })
            }
        );
        const response = await request.json();
        if (response['loginCodeStatus']) {
            setLoading(false);
            dispatch(updateUser(response));
            navigate('/personalArea/' + response['_id']);
        } else {
            setLoading(false);
            setErrors(response);
        }
    }

    return (
        <div className="loginSignup">
            <div className="loginSignupForm">
                <div id="welcomingMessage" className="welcomingMessage">
                    <span className="bigLegend">{loginSignup ? "Already have an account?" : "Don't have an account?"}</span>
                    <span className="shortGuide">{loginSignup ? "Login instead with your personnal information to connect with us." : "Register instead to connect with us."}</span>
                    <div className="welcomeBtn" onClick={() => {
                        setLoginSignup(!loginSignup)
                        if (loginSignup) {
                            setAccountExist(false)
                            handleSignupPos();
                        }
                        else { handleLoginPos(); }
                    }}>
                        {
                            loginSignup ? 'Login' : 'Sign Up'
                        }
                    </div>
                </div>
                {
                    loginSignup ?
                        <Signup loginSignup={loginSignup}
                            handleSignup={handleSignup}
                            handleEmail={handleEmail}
                            handlePassword={handlePassword}
                            errors={errors}
                            userData={userData}
                            loading={loading}
                            setLoading={setLoading} />
                        :
                        accountExist ?
                            <div className="codeOfVerifcationSec">
                                <h2>Two-factor authentication</h2>
                                <p>A verification code has been sent to <strong>{userData['email']}</strong>.</p>

                                <span>Code expires in <Timer time={time} handleTimer={handleTimer} /></span>
                                <span className="error">{errors['loginCode']}</span>

                                <VerificationCode
                                    verifiCode={verifiCode}
                                    handleVerifiCode={handleVerifiCode}
                                    setLoading={setLoading}
                                    loading={loading}
                                    handleCodeSbm={handleCodeSbm}
                                />
                                <div className="options">
                                    <span className="option" onClick={handleResendLoginCode}>Resend code.</span>
                                </div>
                            </div>
                            :
                            <Login
                                loginSignup={loginSignup}
                                handleLogin={handleLogin}
                                handleEmail={handleEmail}
                                handlePassword={handlePassword}
                                errors={errors}
                                loading={loading}
                                setLoading={setLoading}
                                userData={userData}
                            />
                }

            </div>
        </div>
    );
}

export default LoginSignup;