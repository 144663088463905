import PropTypes from "prop-types";
import './LeftSideMenu.css';

const Buttons = ({ option, handleOptions }) =>
    <div className="btnLeftMenu"
        onClick={handleOptions}
        style={option.status ?
            { border: '2px solid #02ada7', borderRadius: '10px' } : {}}>
        <img src={option.icon} alt={option.name + 'logo'}></img>
        <span>{option.name}</span>
    </div>;

Buttons.propTypes = {
    option: PropTypes.shape(
        {
            status: PropTypes.bool.isRequired,
            name: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired
        }
    ).isRequired,
    handleOptions: PropTypes.func.isRequired
};

export const LeftSideMenu = ({ menuOptions, handleOptions }) => {
    return (
        <div className="LeftSideMenu">
            <div className="upperBtnSec">
                {
                    menuOptions.map((option, index) =>
                        <Buttons
                            key={index.toString()}
                            handleOptions={handleOptions}
                            option={option}
                        />)
                }
            </div>
            
        </div>
    )
}

LeftSideMenu.propTypes = {
    menuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            status: PropTypes.bool.isRequired,
            icon: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    handleOptions: PropTypes.func.isRequired,
};