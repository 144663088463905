import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../redux/user";
import { NavLink, Outlet } from "react-router-dom";

function GeneralLayout() {
    const user = useSelector((state) => state.user.userData)
    const dispatch = useDispatch();
    const handleLogout = async () => {
        document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        dispatch(updateUser({
            _id: "",
            email: "",
            password: "",
            userVerified: true,
            order: [],
            createdAt: "",
            updatedAt: "",
            verificationNum: 0,
            __v: 0
        }));
        await fetch(
            'http://localhost:4000/api/logout',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: user['_id'] })
            }
        )
    }
    const userData = useSelector((state) => state.user.userData);

    return (
        <div className="GeneralLayout">
            <div className="bg">
            </div>
            <header>
                <h1><span>BI</span> Crypto</h1>
                <nav>
                    <NavLink className="linksnav" to="/">Home</NavLink>
                    <NavLink className="linksnav" to="howto">How to</NavLink>
                    <NavLink className="linksnav" to="aboutus">About us</NavLink>
                </nav>
                <div className="signupContactsec">
                    {
                        userData['email'] === '' ?
                            <NavLink className="links" to="signupLogin">
                                Sign In
                            </NavLink> :
                            <NavLink className="links" to="/" onClick={handleLogout}>
                                Log out
                            </NavLink>
                    }
                    <NavLink className="links">Contact Us</NavLink>
                </div>
            </header >
            <main>
                <Outlet />
            </main>
            <footer>
                <div className="upperPart">
                    <div className="infoElm">
                        <h3><span>BI</span>Crypt</h3>
                        <p className="addStyle">BiCrypt has developed a user-friendly platform that simplifies the complex world of cryptocurrencies for beginners. </p>
                    </div>
                    <div className="infoElm">
                        <h3>Important links</h3>
                        <div className="linkList">
                            <NavLink className="link">About Us</NavLink>
                            <NavLink className="link">Contact Us</NavLink>
                            <NavLink className="link">Agent Contact</NavLink>
                        </div>
                    </div>
                    <div className="infoElm">
                        <h3>Resources</h3>
                        <div className="linkList">
                            <NavLink className="link">Prices</NavLink>
                            <NavLink className="link">Support</NavLink>
                        </div>
                    </div>
                    <div className="infoElm">
                        <h3>Get in Touch</h3>
                        <p className="addStyle">Question or feedback? We'd love to hear from you.</p>
                        <input
                            type="email"
                            id="email"
                            placeholder="Your email"
                        >
                        </input>
                    </div>
                </div>
                <div className="lowerPart">
                    <span className="copyright">Copyright © BICRYPT 2021</span>
                    <div>
                        <span className="privacy">Privacy Policy</span>
                        <span className="termCondition">Terms & Conditions</span>
                    </div>
                </div>
            </footer>
        </div >
    );
}

export default GeneralLayout;