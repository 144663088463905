import { createSlice } from "@reduxjs/toolkit";
export const adminSlice = createSlice({
    name: "admin",
    initialState: {
        adminData: {
            adminId: "",
            password: "",
            multifactorAuthCode: 0,
            sessionAuthentication: false
        }
    },
    reducers: {
        updateAdmin: (state, action) => {
            state.adminData = action.payload;
        }
    }
});

export const { updateAdmin } = adminSlice.actions;
export default adminSlice.reducer;