import { useEffect } from "react";

const Timer = ({ time, handleTimer }) => {
    useEffect(() => {
        const { min, sec } = time;
        let newMin; let newSec;

        if (!(min === 0) || !(sec === 0)) {
            const timerID = setInterval(() => {
                newMin = min;
                newSec = sec - 1;

                if (sec === 0) {
                    newMin -= 1;
                    if (newMin >= 0) newSec = 59;
                }

                handleTimer(
                    {
                        min: newMin,
                        sec: newSec
                    }
                );

            }, 1000);
            return () => clearInterval(timerID);
        }
    }, [time,handleTimer]);

    return (
        <span>{time['min']}:{time['sec']}</span>
    )
}

export default Timer;