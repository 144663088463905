import { createSlice } from "@reduxjs/toolkit";
export const userSlice = createSlice({
    name: "user",
    initialState: {
        userData: {
            _id: "",
            email: "",
            password: "",
            userVerified: true,
            order: [],
            createdAt: "",
            updatedAt: "",
            verificationNum: 0,
            __v: 0
        }
    },
    reducers: {
        updateUser: (state, action) => {
            state.userData = action.payload;
        }
    }
});

export const { updateUser } = userSlice.actions;
export default userSlice.reducer;