import PropTypes from 'prop-types';
import './MessagesSec.css';

const Message = ({ messages, date }) => {
    return (
        <div className='message'>
            <span className='date'>{date}</span>

            {
                messages.map(
                    (message, index) => (
                        <div className='notification'
                            key={index.toString()}
                            style={{ animation: `notificationSlide ${0.8 + index * 0.2}s forwards ease-in-out` }}>
                            <div className='leftSide'>
                                <img src={message.image} alt='Crypto currency logo'></img>
                                <div>
                                    <span className='title'>{message.title}</span>
                                    <span className='description'>{message.description}</span>
                                </div>
                            </div>
                            <span className='time'>{message.time}</span>
                        </div>
                    ))}
        </div>
    );
};
Message.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            time: PropTypes.string.isRequired,
        })
    ).isRequired,
    date: PropTypes.string.isRequired,
};

export const MessagesSec = ({ messages }) => {

    return (
        <div className='MessageSec'>
            {
                messages.map((elem, index) =>
                    <Message messages={elem['messages']} key={index.toString()} date={elem['date']} index={index} />
                )
            }
        </div>
    )
}
MessagesSec.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            date: PropTypes.string.isRequired,
            messages: PropTypes.arrayOf(
                PropTypes.shape({
                    image: PropTypes.string.isRequired,
                    title: PropTypes.string.isRequired,
                    description: PropTypes.string.isRequired,
                    time: PropTypes.string.isRequired,
                })
            )
        })
    )
}