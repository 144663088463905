import emailIcon from "../svg/emailIcon.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../redux/user";
import VerificationCode from "../components/verificationCodeEntry/verificationCode";
import Timer from "../components/Timer/Timer";

function SignupSuccess() {
    const { email } = useSelector((state) => state.accountVerify);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ accountActivation: '' });
    const [codeResent, setCodeResent] = useState('');
    const [time, setTimer] = useState({
        min: 1,
        sec: 59
    })
    const handleTimer = (val) => setTimer(val);
    const [verifiCode, setVerifiCode] = useState([
        null, null, null, null, null, null
    ]);

    const handleVerifiCode = (e) => {
        const index = parseInt(e.target.id);
        let value = parseInt(e.target.value);
        const codeEntry = [...verifiCode];
        if (value > 9) value = 9;
        if (value < 0) value = 0;
        codeEntry[index] = value;
        if (index !== 5 && !isNaN(value)) {
            let input = document.getElementById((index + 1).toString());
            input.select();
        }
        if (index !== 0 && isNaN(value)) {
            let input = document.getElementById((index - 1).toString());
            input.select();
        }
        setErrors({ accountActivation: '' });
        handleCodeResent('')
        setVerifiCode(codeEntry);
    }
    const handleErrors = (err) => {
        setErrors(err)
    }
    const handleCodeResent = (val) => {
        setCodeResent(val);
    }
    const handleResendCode = async () => {
        setErrors({ accountActivation: '' });
        handleCodeResent('')
        const token = document.cookie.replace("jwt=", "")
        const request = await fetch(
            'http://localhost:4000/api/codeConfirmationResent',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                withCredentials: true,
                credentials: 'include'
            }
        )
        const response = await request.json();
        setTimer({
            min: 1,
            sec: 59
        })
        handleCodeResent(response['accountActivation']);
    }
    const handleCodeSbm = async (e) => {
        e.preventDefault();
        const code = { code: verifiCode.filter(elem => !isNaN(elem)).join('') };
        if (code['code'].length === 6) {
            const token = document.cookie.replace("jwt=", "");
            const request = await fetch(
                'http://localhost:4000/api/codeConfirmation',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    withCredentials: true,
                    credentials: 'include',
                    body: JSON.stringify(code)
                }
            )
            const response = await request.json()

            if (response['userVerified']) {
                dispatch(updateUser(response));
                navigate('/personalArea/' + response['_id']);
            } else {
                let error = { accountActivation: '' };
                if (response['accountActivation']) {
                    switch (response['accountActivation']) {
                        case 'Client account action failed to update to true.':
                            error['accountActivation'] = 'Activation currently unavailable.';
                            break;
                        case 'Wrong code of confirmation.':
                            error['accountActivation'] = 'Incorrect code of activation.';
                            break;
                        case 'client email for code of confirmation not found.':
                            error['accountActivation'] = 'Email was not saved.';
                            break;
                        case 'codeExpired':
                            error['accountActivation'] = 'Activation code has expired.';
                            break;
                        default:
                            break;
                    }
                    handleErrors(error);
                }
            }
        }
    }
    const handleChangeEmail = async () => {
        const token = document.cookie.replace("jwt=", "");
        const request = await fetch(
            'http://localhost:4000/api/changeEmailSignUp',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                withCredentials: true,
                credentials: 'include'
            }
        )
        const response = await request.json();
        if (response['email'] === 'user deleted') {
            document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            navigate('/signupLogin');
        } else console.log('failed to change email.');
    }
    return (
        <div className="SignupSuccess">
            <div className="formSection">
                <div className="upperPart">
                    <img src={emailIcon} alt="email icon"></img>
                    <h1>VERIFY YOUR EMAIL ADDRESS</h1>
                </div>

                <div className="middlePart">
                    <p style={{ textAlign: 'center' }}>A verification code has been sent to <strong>{email}</strong></p>

                    <p>Please check your inbox and enter the verification code below to verify your email address.
                        The code will expire in <strong><Timer time={time} handleTimer={handleTimer} /></strong>.</p>
                    {
                        errors !== null ?
                            <span className="errorCode" >{errors['accountActivation']}</span> :
                            <></>
                    }
                    {
                        codeResent ?
                            <span className="codeResent">{codeResent}</span> :
                            <></>
                    }
                    <VerificationCode
                        verifiCode={verifiCode}
                        handleVerifiCode={handleVerifiCode}
                        setLoading={setLoading}
                        loading={loading}
                        handleCodeSbm={handleCodeSbm}
                    />
                </div>

                <div className="bottomPart">
                    <span className="link" onClick={handleResendCode}>Resend Code</span>
                    <span className="link" onClick={handleChangeEmail}>Change Email</span>
                </div>
            </div>
        </div>
    );
}

export default SignupSuccess;